/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../../../SharedModules/TabPanel/TabPanel';
import Dialog from '@material-ui/core/Dialog';
import { Link, Prompt } from 'react-router-dom';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as ErrorConstants from '../ErrorCheck';
import GrossLevelDetailsComponent from './GrossLevelDetailsComponent';
import GrossLevelExemptionDetailComponent from './GrossLevelExemptionDetailComponent';
import GrossLevelHistoryTableComponent from './GrossLevelHistory';
import ErrorMessages from '../../../../../../SharedModules/Errors/ErrorMessages';
import PaymentScheduleTable from './paymentScheduleTable';
import moment from 'moment';
import {
  getUTCTimeStamp,
  generateUUID,
  compareTwoDates,
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../../../../SharedModules/DateUtilities/DateUtilities';
import { redirectSystemID } from '../../../../../../SharedModules/TabRedirections/TabRedirections';
import { systemListActions } from '../../../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions';
import {
  getWriteOffRestablishmentDetailsReset,
  getWriteOffDetailsReset,
  getDropdownDataAction,
  getWriteOffDetails,
  getWriteOffRestablishmentDetails,
  getPayeeIdType,
  searchFCNAccountReceivableAction,
  editGrossLevel,
  ARTransferEligibility,
  ARReverseEligibility,
  ARAdjustEligibility,
  getDefaultRecoupmentDetails,
  agingDaysList,
  searchClaimsReceivableAction
} from '../../../../Store/Actions/accountReceivable/accountReceivableActions';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessages from '../../../../../../SharedModules/Errors/SuccessMessage';
import NoSaveMessage from '../../../../../../SharedModules/Errors/NoSaveMessage';
import {
  DialogContent,
  DialogActions
} from '../../../../../../SharedModules/Dialog/DialogUtilities';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router';

import numeral from 'numeral';
import * as ARSearchConstants from '../../ARSearch/ARSearchConstants';
import * as AppConstants from '../../../../../../SharedModules/AppConstants';
import dropdownCriteria from './GrossLevelAddUpdate.json';
import {
  getAuditLogDetailsAccountingAction,
  getMaxFrequencyParameter,
  getSystemParameterSpecificValue
} from '../../../../../../SharedModules/store/Actions/SharedAction';
import AuditTableComponent from '../../../../../../SharedModules/AuditLog/AuditTableComponent';
import Footer from '../../../../../../SharedModules/Layout/footer';
import { searchSingleRecordFinancialEntity } from '../../../../../FinancialEntities/Store/Actions/FinancialEntityActions';
import Notes from '../../../../../../SharedModules/Notes/Notes';
import Attachments from '../../../../../../SharedModules/Attachments/Attachments';
import NavHOC from '../../../../../../SharedModules/Navigation/NavHOC';
import Radio from '@material-ui/core/Radio';
import { searchSystemParameterAction } from '../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import { checkingDecimal, determineAmountToUse, getLongDescription, parseAmount, checkValueExists } from '../../../../../../SharedModules/CommonUtilities/commonUtilities';

import { GET_APP_DROPDOWNS } from "../../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../../../SharedModules/Dropdowns/NativeDropDown';

export default NavHOC({
  Wrapped: FinancialAccountGrossLevelEdit,
  action: searchClaimsReceivableAction,
  selector: 'accountReceivableState.payloadClaims',
  url: '/FinancialAccountGrossLevelUpdate',
  actionKey: 'AccountReceivable'
});
function FinancialAccountGrossLevelEdit(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [recoupmentTypeClicked, setRecoupmentTypeClicked] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];
  let systemParameterPayloadPercentage = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );

  const addNotes = data => {
    setFocusCheck(true);
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const onReasonCd = (values) => dispatch(systemListActions(values));
  let reasonCdData = useSelector(state => state.appConfigState.systemListState);
  const [reasonCd, setReasonCd] = React.useState([]);
  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowErrorText('');
    setShowError([]);
  };
  const [dropDownCheckList, setDropDownCheckList] = React.useState([]);
  let paramsData;
  let scheduleData;
  const optSelectOne = DEFAULT_DD_VALUE;
  const newDate = 'MM/DD/YYYY';
  const finRSNCD = 'F1#F_RSN_CD';
  const muiDisabled = 'Mui-disabled';
  const fin1009 = 'Financial#1009';

  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };
 const recoupmentValues = props.location.state?.claimsReceivableData?.recoupmentVO;

  const [values, setValues] = React.useState({
    originalARAmount: 0.0,
    approval: 'N',
    underAppeal: 'N',
    fiscalYear: '',
    categoryOfService: optSelectOne,
    collocationCode: optSelectOne,
    recoupmentTypeCode: optSelectOne,
    installmentValue: '',
    percentValue: '',
    frequency: recoupmentValues?.frequency,
    exemptFromPenalty: 'N',
    exemptFromInterest: 'N',
    exemptFromCollectionAgency: 'N',
    amountDue: '',
    defaultOnPaymentSchedule: 'N',
    lob: optSelectOne,
    payeeType: optSelectOne,
    payeeIdTypeCode: optSelectOne,
    reasonCode: '',
    activityCode: optSelectOne,
    systemPayeeId: '',
    payeeId: '',
    payeePayerName: '',
    fcn: '',
    adjustedARAmount: '',
    balanceAmount: '',
    relatedFTTCN: '',
    establishedDate: '',
    lastDateApplied: '',
    arAgeInDays: '',
    arLettersent: '',
    categoryOfServiceData: [],
    collocationCodeData: [],
    calculatedInterest: '',
    calculatedPenalty: '',
    feesAssesed: '',
    frequencyData: [],
    recoupmentData: [],
    payeeStatus: DEFAULT_DD_VALUE,
    originalARAmountData: 0,
    balanceAmountData: 0,
    paymentScheduleType: optSelectOne,
    recoupmentStartDate: null,
    numberofInstallments: '',
    interestCalculation: 'N',
    interestPercentage: '',
    paymentScheduleFrequency: optSelectOne,
    recoupedAmount: '0.00',
    installmentAmount: '',
    federal: '',
    state: '',
    county: '',
    other: '',
    startDateStr: '',
    atrNumber: ''
  });
  const getAmountToUse = () => {
    const adjustedAmount = parseAmount(values.adjustedARAmount);
    const originalAmount = parseAmount(values.originalARAmount);
    const balanceAmount = parseAmount(values.balanceAmount);
    const installmentValue = parseAmount(values.installmentValue);
    const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
    return { balanceAmount, installmentValue, amountToUse };
  };

  useEffect(() => {
    if (values.recoupmentTypeCode === 'N') {
      setPaymentSchedleData([]);
      const percentValue = '0';
      const installmentValue = '0';
      const recoupmenttypeCode = 'N';
      const frequency = optSelectOne;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));

      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === 'S') {
      const percentValue = '';
      const installmentValue = '';
      const recoupmenttypeCode = 'S';
      const frequency = optSelectOne;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));

      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === 'A') {
      setPaymentSchedleData([]);
      const percentValue = '';
      const installmentValue =
        values.installmentValue === ''
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.installmentAmount
            ? grossLevel.recoupmentVO.installmentAmount
            : '0'
          : values.installmentValue;
      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.recoupmentTypeCode
            ? grossLevel.recoupmentVO.recoupmentTypeCode
            : 'A'
          : values.recoupmentTypeCode;
      const frequency =
        values.frequency === optSelectOne
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.frequency
            ? grossLevel.recoupmentVO.frequency
            : '1'
          : values.frequency;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));

      setSelectedRecoupmentDate(
        selectedRecoupmentDate === null
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.startDateStr
            ? grossLevel.recoupmentVO.startDateStr
            : new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          : selectedRecoupmentDate
      );
    }
    if (values.recoupmentTypeCode === 'B') {
      setPaymentSchedleData([]);
      const percentValue =
        values.percentValue === ''
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.percentageOriginalAmount
            ? grossLevel.recoupmentVO.percentageOriginalAmount
            : '100.00'
          : values.percentValue;
      const installmentValue = '';

      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.recoupmentTypeCode
            ? grossLevel.recoupmentVO.recoupmentTypeCode
            : 'A'
          : values.recoupmentTypeCode;
      const frequency =
        values.frequency === optSelectOne
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.frequency
            ? grossLevel.recoupmentVO.frequency
            : '1'
          : values.frequency;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));

      setSelectedRecoupmentDate(
        selectedRecoupmentDate === null
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.startDateStr
            ? grossLevel.recoupmentVO.startDateStr
            : new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          : selectedRecoupmentDate
      );
    }
    if (values.recoupmentTypeCode === 'D') {
      setPaymentSchedleData([]);
      if (recoupmentTypeClicked) {
        onGetRecoupmetDetails(claimsData);
      }
      setValues({
        ...values,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne,
        paymentScheduleType: optSelectOne,
		frequency: recoupmentValues?.frequency
	  ? values.frequency === DEFAULT_DD_VALUE
		? null
		: values.frequency
	  : null
      }
      );
      searchSystemParameterPercentage(systemParamValuetoPercentage);
    }
  }, [values.recoupmentTypeCode]);

  useEffect(() => {
    systemParameterPayloadPercentage = null;
    window.scrollTo(0, 0);
    getDropdownData(dropdownCriteria);
    dispatch(agingDaysList());
    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: 'F1',
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format('YYYY-DD-MM')
    });
    if (props.location.state.claimsReceivableData) {
      paramsData = props.location.state.claimsReceivableData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      if (
        paramsData &&
        paramsData.attachmentsList &&
        paramsData.attachmentsList.length > 0
      ) {
        const initialGrosslevelAttachmentList = paramsData.attachmentsList;
        setAttachmentsTableData(initialGrosslevelAttachmentList);
      } else {
        setAttachmentsTableData([]);
      }
      setAttachmentFile([]);
    }
    if (
      props.location.state &&
      props.location.state.claimsReceivableData &&
      props.location.state.claimsReceivableData.schedulePaymentList
    ) {
      scheduleData =
        props.location.state.claimsReceivableData.schedulePaymentList;
      setPaymentSchedleData(scheduleData);
    }
  }, []);

  useEffect(() => {
    if (
      systemParameterPayloadPercentage &&
      systemParameterPayloadPercentage.length > 0
    ) {
      const systemParameterPercentage =
        systemParameterPayloadPercentage[0].systemParameterDetail[0].valuePCT *
        100;
      const percentValue = systemParameterPercentage;
      const installmentValue = '';
      const recoupmenttypeCode = 'D';
      const frequency = '1';
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: recoupmentValues?.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));

      setSelectedRecoupmentDate(
        grossLevel &&
          grossLevel.recoupmentVO &&
          grossLevel.recoupmentVO.startDateStr
          ? grossLevel.recoupmentVO.startDateStr
          : new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
      );
    }
  }, [systemParameterPayloadPercentage]);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: optSelectOne,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  useEffect(() => {
    onReasonCd(Dropdowns.AR_BULK_RN9023_CD);
  },[]);

  useEffect(() => {
    if (reasonCdData && reasonCdData.payload ){
		let date = moment(new Date()).format("MM/DD/YYYY");
		const nonVoidedRsnCdsList = reasonCdData.payload[0].systemListDetails?.filter(
			(code, index) => code.voidDate === null
		  );
		  const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
			(code, index) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
		  );
		  const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue);
		  setReasonCd(nonVoidedRsnCds);
      }
    }, [reasonCdData]);
  // Audit Log Implementation starts
  const [close, setClose] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const onClickAuditLog = fcn => {
    dispatch(
      getAuditLogDetailsAccountingAction(fcn, '', false, 'auditLogData')
    );
  };
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const onClickAuditDialogLog = data => {
    setShowLogDialogTable(true);
    dispatch(
      getAuditLogDetailsAccountingAction(
        data.scheduleSK,
        '',
        true,
        'auditDialogData'
      )
    );
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  const auditLogData = useSelector(state => state.sharedState.auditLogData);
  const auditDialogData = useSelector(
    state => state.sharedState.auditDialogData
  );
  const [auditDialogRowData, setAuditDialogRowData] = React.useState([]);
  const [auditRowData, setAuditRowData] = React.useState([]);
  const [frequencyDataDropdown, setFrequencyDataDropdown] = React.useState([]);
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
    setPaymentSettingChanged(generateUUID());
    setPaymentSchedleData([]);
  };
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });
  const [resetPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );

  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      });
      setSelectedRecoupmentStartDate(null);
    }
  }, [paymentScheduleChanged]);

  const [paymentInd, setpaymentInd] = React.useState(false);
  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'installmentAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
      } else {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
    } else {
    setPaymentScheduleVO({
      ...paymentScheduleVO,
      [name]: event.target.value
    });
  }
  };
  const [paymentSettingChanged, setPaymentSettingChanged] = React.useState(
    false
  );
  useEffect(() => {
    if (paymentSettingChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
    }
  }, [paymentSettingChanged]);

  const [scheduleTable, setScheduleTable] = React.useState(true);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState('');
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState('');
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState('');
  const [frequencyError, setFrequencyError] = React.useState('');
  const [interestCalError, setInstallmentCalError] = React.useState('');

  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);
  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState.frequencyParameter
  );

  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };

  useEffect(() => {
    if (auditDialogData) {
      setAuditDialogRowData(auditDialogData);
    }
    if (auditLogData) {
      setAuditRowData(auditLogData);
    }
  }, [auditDialogData, auditLogData]);

  // Audit Log Implementation ends
  // Notes Integration End
  const [amountObj, setAmountObj] = React.useState({
    tempOriginalARAmount: '',
    tempBalanceAmount: ''
  });

  const [color, setColor] = React.useState('');
  const errorMessagesArray = [];
  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [ARletterSent, setARletterSent] = React.useState('');
  const ARLetterSentDetails = useSelector(
    state => state.accountReceivableTransferState.agingDaysList
  );
  useEffect(() => {
    if (
      ARLetterSentDetails &&
      ARLetterSentDetails.systemFailure === undefined
    ) {
      ARLetterSentDetails.map(var1 => {
        if (grossLevelDetails.agingCode === var1.code) {
          setARletterSent(var1.description);
        }
      });
    }
  }, [ARLetterSentDetails]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [adjustedReasonCode, setAdjustedReasonCode] = React.useState([]);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [reasonCodeDD, setReasonCodeDD] = React.useState([]);
  const [rcTitle, setRCTitle] = React.useState(null);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [recoupmentData, setRecoupmentData] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [frequencyData, setFrequencyData] = React.useState([]);
  const [COSData, setCOSData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [reasonCodeMapArray, setReasonCodeMapArray] = React.useState([]);
  const [retainDefaultDate, setRetainDefaultDate] = React.useState(null);
  const [retainDueDate, setRetainDueDate] = React.useState(null);
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [defaultedIndicator, setDefaultedIndicator] = React.useState('N');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (open) {
      document.getElementById('form_pop_up').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [open]);
  const [
    {
      showOriginalArAmmountError,
      showFiscalYearError,
      showCollocationCodeError,
      showRecoupmentTypeCodeError,
      showDueDateError,
      showAmountDueError,
      showInvalidBeginDateError,
      showInvalidEndDateError,
      showEndDateLesserError,
      showBeginDateRequiredError,
      showEndDateRequiredError,
      showPercentError,
      showInstallementError,
      showStartDateError,
      showStartDateRequiredError,
      showDueDateGreaterError,
      showMinBeginDateError,
      showMinEndDateError,
      showMinRecoupmentDateError,
      showMinDueDateError,
      showMinDefaultDateError,
      showDefaultDateError,
      showDueDateInvalidError,
      showNegativeAmountError,
      showGreaterThanZeroAmountError,
      showOriginalArAmountPopError,
      showFrequencyError,
      showATRError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showOriginalArAmountErrorText,
      showFiscalYearErrorText,
      showCollocationCodeErrorText,
      showRecoupmentTypeCodeErrorText,
      showDueDateErrorText,
      showAmountDueErrorText,
      showInvalidBeginDateText,
      showInvalidEndDateInvalidText,
      showEndDateLesserErrorText,
      showBeginDateRequiredErrorText,
      showEndDateRequiredErrorText,
      showPercentErrorText,
      showInstallmentErrorText,
      showStartDateErrorText,
      showStartDateRequiredErrorText,
      showDueDateGreaterErrorText,
      showMinBeginDateErrorText,
      showMinEndDateErrorText,
      showMinRecoupmentDateErrorText,
      showMinDueDateErrorText,
      showMinDefaultDateErrorText,
      showDefaultDUeDateErrorText,
      showDueDateInvalidErrorText,
      showNegativeAmmountErrorText,
      showGreaterThanZeroAmountText,
      showOriginalArAmountPopErrorText,
      showFrequencyErrorText,
      showATRErrorText
    },
    setShowErrorText
  ] = React.useState('');

  const [popupErrorMessages, setPopUpErrorMessage] = React.useState([]);

  const [
    deleteSchedulePaymentList,
    setDeleteSchedulePaymentList
  ] = React.useState([]);
  const [schedulePaymentMap, setSchedulePaymentMap] = React.useState({});
  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [tabFour, setTabFour] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState({});
  const [editData, setEditData] = React.useState({});
  const [retainValues, setRetainValues] = React.useState({});
  const [retainDueAmout, setREtainDueAmout] = React.useState('');
  const [retainDefaultIndicator, setDefaultIndicator] = React.useState('N');
  const [resetDueAmount, setResetDueAmount] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [writeOffSaveDetails, setWriteOffSaveDetails] = React.useState();
  const [ClaimsReceivableSaveWO, setClaimsReceivableSaveWO] = React.useState();
  const checkARTransfer = values => {
    dispatch(ARTransferEligibility(values));
  };
  const transferDetails = useSelector(
    state => state.accountReceivableState.arTransferEligibleDetails
  );

  const claimsReceivableDataWriteOff = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const writeOffDetailsSave = useSelector(state => state.writeOffState.saveWriteOffData);

  React.useEffect(() => {
    if (writeOffDetailsSave !== undefined) {
      setWriteOffSaveDetails(writeOffDetailsSave);
      dispatch(searchClaimsReceivableAction(writeOffDetailsSave.newFCN));
    }
  }, [writeOffDetailsSave]);

  React.useEffect(() => {
    if (claimsReceivableDataWriteOff !== undefined) {
      setClaimsReceivableSaveWO(claimsReceivableDataWriteOff);
    }
  }, [claimsReceivableDataWriteOff]);

  const checkARREverse = values => {
    dispatch(ARReverseEligibility(values));
  };
  const reverseDetails = useSelector(
    state => state.accountReceivableState.arReverseEligibleDetails
  );
  const checkARAdjust = values => {
    dispatch(ARAdjustEligibility(values));
  };
  const adjustDetails = useSelector(
    state => state.accountReceivableState.arAdjustEligibleDetails
  );
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onGrossLevelEdit = (value, index) =>
    dispatch(
      editGrossLevel(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : []
      )
    );
  const onSearch = values => dispatch(searchFCNAccountReceivableAction(values));
  const [writeOff, setWriteOff] = React.useState(true);
  const popUpErrorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  let dropdown = [];
  let historyPayload = [];

  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);
  const grossLevelDetails = writeOffSaveDetails && writeOffSaveDetails.respcode === '01' && ClaimsReceivableSaveWO !== undefined
    ? ClaimsReceivableSaveWO : props.location.state.claimsReceivableData;
  const claimsData = grossLevelDetails;
  historyPayload = grossLevelDetails
    ? grossLevelDetails.historyList
      ? grossLevelDetails.historyList
      : []
    : [];
  React.useEffect(() => {

    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
  }, [searchSingleFinancialEntityRes]);

  const onSystemPayeeIdclick = systemPayeeId => event => {

    dispatch(searchSingleRecordFinancialEntity(systemPayeeId));
    setRedirect(1);
  };
  // System Payee redirection ends
  useEffect(() => {
    if (allowNavigation) {
      setSystemSuccesMessages([]);
    }
  }, [allowNavigation]);
  const [errorMessageCopy, setErrorMessageCopy] = React.useState([]);

  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState(
    props.location.state && props.location.state.systemSuccessMessages
      ? props.location.state.systemSuccessMessages
      : []
  );
  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  const reasonCodeMapdropdown = useSelector(
    state => state.accountReceivableState.subdropdownData
  );
  const addGrossLevelAddResponse = useSelector(
    state => state.accountReceivableState.GrossLevelEditData
  );
  const newData = useSelector(state => state.accountReceivableState.payload);

  const fetchWriteOffData = values => {
    dispatch(getWriteOffDetails(values));
  };
  const writeOffDetails = useSelector(
    state => state.accountReceivableState.writeOffData
  );

  const fetchWriteOffReEstablishmentData = values => {
    dispatch(getWriteOffRestablishmentDetails(values));
  };
  const writeOffReEstablishmentDetails = useSelector(
    state => state.accountReceivableState.writeOffRestablishmentData
  );

  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };

  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState.fetchRecoupmetDetails
  );

  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && !recoupmentTypeClicked
    ) {
      const amount = '';
      const defaultOnPaymentSchedule = 'Y';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      if (
        recoupmentsection.frequency !== values.frequency ||
        recoupmentsection.recoupment !== values.recoupmentTypeCode ||
        Number(recoupmentsection.installment) !== Number(values.installmentValue) ||
        Number(recoupmentsection.percent) !== Number(values.percentValue) ||
        getRecoupmetDetails.recoupmentVO.startDateStr !== moment(selectedRecoupmentDate).format(newDate)
      ) { setFocusCheck(true); }
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(newDate));
      seterrorMessages([]);
    } else if (getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && recoupmentTypeClicked) {
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(newDate));
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);
  // fcn search use selector

  const sucessMessages = [];
  const systemErrorMessges = [];
  useEffect(() => {

    if (addGrossLevelAddResponse && !addGrossLevelAddResponse.systemFailure) {
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === '03'
      ) {
        const error = [];
        setSystemSuccesMessages([]);
        error.push(ErrorConstants.TRANSACTION_ERROR);
        seterrorMessages(error);
      }
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === '01' &&
        addGrossLevelAddResponse.respdesc !== ''
      ) {
        const success = addGrossLevelAddResponse.respdesc;
        setAllowNavigation(false);
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        sucessMessages.push(success);
        setSystemSuccesMessages(sucessMessages);

        setFocusCheck(false);

        onSearch(grossLevelDetails.receivableFCN);
      }
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === '02' &&
        addGrossLevelAddResponse.respdesc !== ''
      ) {
        const error = addGrossLevelAddResponse.errorMessages;
        setSystemSuccesMessages([]);
        if (error && error.length > 0) {
          error.map((option, index) => {
            systemErrorMessges.push(option);
          });
        }
        seterrorMessages(systemErrorMessges);
      }
    }
    if (addGrossLevelAddResponse && addGrossLevelAddResponse.systemFailure) {
      const error = [];
      setSystemSuccesMessages([]);
      error.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages(error);
    }
  }, [addGrossLevelAddResponse]);

  useEffect(() => {
    if (grossLevelDetails) {
      setGrossLevel(JSON.parse(JSON.stringify(grossLevelDetails)));
      setInput(grossLevelDetails);
      const scheduleDataInitial = grossLevelDetails.schedulePaymentList.map(
        (option) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
      setAmountObj({
        tempOriginalARAmount: grossLevelDetails.originalAmount,
        tempBalanceAmount: grossLevelDetails.balanceAmount
      });
      if (grossLevelDetails.activityTypeCode === 'WO') {
        setWriteOff(false);
      }
    }
  }, [grossLevelDetails, COSData, fundCodeData, frequencyData, recoupmentData]);

  React.useEffect(() => {
    if (
      grossLevelDetails?.payeePayerTypeCode &&
      grossLevelDetails.payeePayerTypeCode !== optSelectOne
    ) {
      onPayeeTypeIdDropDown(grossLevelDetails.payeePayerTypeCode);
    }
  }, [grossLevelDetails]);

  useEffect(() => {

    if (newData && newData.respcode === '02' && newData.respdesc !== '') {
      errorMessagesArray.push(newData.respdesc);
      seterrorMessages(errorMessagesArray);
    }
    if (
      newData &&
      newData.respcode === undefined &&
      newData.systemFailure === undefined
    ) {
      grossLevelDetails.versionNo = newData.versionNo;
      setGrossLevel(JSON.parse(JSON.stringify(newData)));
      const scheduleDataInitial = newData.schedulePaymentList.map(
        (option, index) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
      setInput(newData);
      setAmountObj({
        tempOriginalARAmount: newData.originalAmount,
        tempBalanceAmount: newData.balanceAmount
      });
      if (newData.noteSetVO) {
        const noteSetVO = newData.noteSetVO;
        const notesTable = newData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    if (newData && newData.systemFailure !== undefined) {
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      newData &&
      newData.attachmentsList &&
      newData.attachmentsList.length > 0
    ) {
      const grosslevelUpdateAttachmentList = newData.attachmentsList;
      setAttachmentsTableData(grosslevelUpdateAttachmentList);
    }
    setAttachmentFile([]);
  }, [newData ? newData.versionNo : newData]);

  useEffect(() => {
    if (
      addDropdowns &&
      addDropdowns[finRSNCD]
    ) {
      setReasonCodeMapArray(
        addDropdowns[finRSNCD]
      );
      for (var key in addDropdowns[finRSNCD]) {
        if (
          addDropdowns[finRSNCD][key].code ===
          grossLevelDetails.accountReceivableReasonCode
        ) {
          setReasonCodeData(
            addDropdowns[finRSNCD][key].description
          );
        }
      }
    }
    if (
      addDropdowns &&
      addDropdowns[finRSNCD]
    ) {
      for (var historyValue in historyPayload) {
        for (var key in addDropdowns[finRSNCD]) {
          if (
            addDropdowns[finRSNCD][key].code ===
            historyPayload[historyValue].reasonCode
          ) {
            historyPayload[historyValue].reasonCode =
              addDropdowns[finRSNCD][
                key
              ].description;
            historyPayload[historyValue].reasonCodeLongDesc =
              addDropdowns[finRSNCD][
                key
              ].longDescription;
          }
        }
      }
    }
  }, [addDropdowns]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#6007']) {
        setDropDownCheckList(dropdown.listObj['Financial#6007']);
      }
      if (dropdown.listObj['Financial#8']) {
        setAdjustedReasonCode(dropdown.listObj['Financial#8']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityTypeData(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['Financial#1007']) {
        setRecoupmentData(dropdown.listObj['Financial#1007']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
      if (dropdown.listObj[fin1009]) {
        setFrequencyData(dropdown.listObj[fin1009]);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCOSData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setAccountRRCodes(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
      if (dropdown.listObj[fin1009]) {
        setFrequencyDataDropdown(dropdown.listObj[fin1009]);
      }
      if (dropdown.listObj['Financial#6017']) {
        setPaymentScheduleTypeData(dropdown.listObj['Financial#6017']);
      }
      if (dropdown.listObj['Financial#6016']) {
        setFrequencyRecoupData(dropdown.listObj['Financial#6016']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (
      payoutRCodes.length > 0 &&
      receiptRCodes.length > 0 &&
      accountRRCodes.length > 0 &&
      dispositionRCodes.length > 0
    ) {
      for (var historyValue in historyPayload) {
        if (historyPayload[historyValue].receivableFCN) {
          if (historyPayload[historyValue].receivableFCN.length === 14) {
            payoutRCodes.map(value => {
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].activityType = 'Payout';
              }
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
              if (values.reasonCode === value.code) {
                setReasonCodeDD(payoutRCodes);
                setRCTitle(value.longDescription);
              }
            });
            receiptRCodes.map(value => {
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].activityType = 'Receipt';
              }
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
              if (values.reasonCode === value.code) {
                setReasonCodeDD(receiptRCodes);
                setRCTitle(value.longDescription);
              }
            });
            accountRRCodes.map(value => {
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].activityType = 'AccountR';
              }
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
              if (
                values.reasonCode === value.code ||
                values.reasonCode === value.description
              ) {
                setReasonCodeDD(accountRRCodes);
                setRCTitle(value.longDescription);
              }
            });
            dispositionRCodes.map(value => {
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].activityType = 'AccountR';
              }
              if (
                historyPayload[historyValue].reasonCode === value.description
              ) {
                historyPayload[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
              if (
                values.reasonCode === value.code ||
                values.reasonCode === value.description
              ) {
                setReasonCodeDD(dispositionRCodes);
                setRCTitle(value.longDescription);
              }
            });
          } else {
            historyPayload[historyValue].activityType = 'FTTCN';
            historyPayload[historyValue].reasonCodeLongDesc =
              historyPayload[historyValue].reasonCode;
            setReasonCodeDD(reasonCodeMapArray);
          }
        }
      }
      payoutRCodes.map(value => {
        if (
          values.reasonCode === value.code ||
          values.reasonCode === value.description
        ) {
          setReasonCodeDD(payoutRCodes);
          setRCTitle(value.longDescription);
        }
      });
      receiptRCodes.map(value => {
        if (
          values.reasonCode === value.code ||
          values.reasonCode === value.description
        ) {
          setReasonCodeDD(receiptRCodes);
          setRCTitle(value.longDescription);
        }
      });
      accountRRCodes.map(value => {
        if (
          values.reasonCode === value.code ||
          values.reasonCode === value.description
        ) {
          setReasonCodeDD(accountRRCodes);
          setRCTitle(value.longDescription);
        }
      });
      dispositionRCodes.map(value => {
        if (
          values.reasonCode === value.code ||
          values.reasonCode === value.description
        ) {
          setReasonCodeDD(dispositionRCodes);
          setRCTitle(value.longDescription);
        }
      });
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog(values.fcn);
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditDialogRowData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditDialogRowData([]);
    }
  }, [auditLogRow]);

  useEffect(() => {
    if (
      writeOffDetails &&
      writeOffDetails.length !== 0 &&
      !writeOffDetails.SystemError
    ) {
      if (writeOffDetails.errorMessgaes !== null) {

        seterrorMessages(writeOffDetails.errorMessgaes);
      } else {
        props.history.push({
          pathname: '/WriteOff',
          state: { writeOffDetails, values: valuesFromAREdit,
            payloadPayees: payloadPayees,
            addDropdowns:addDropdowns,
            payeeIDTypeDropdown :payeeIDTypeDropdown }
        });
      }
    }
    if (writeOffDetails && writeOffDetails.SystemError === true) {

      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffDetails]);

  useEffect(() => {
    if (
      writeOffReEstablishmentDetails &&
      !writeOffReEstablishmentDetails.SystemError &&
      writeOffReEstablishmentDetails.length !== 0
    ) {
      if (writeOffReEstablishmentDetails.errorMessgaes !== null) {

        seterrorMessages(writeOffReEstablishmentDetails.errorMessgaes);
      } else {

        props.history.push({
          pathname: '/WriteOffReEstablishment',
          state: { writeOffReEstablishmentDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (
      writeOffReEstablishmentDetails &&
      writeOffReEstablishmentDetails.SystemError === true
    ) {

      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffReEstablishmentDetails]);

  useEffect(() => {
    if (values.underAppeal === 'Y') {
      const percentValue = '0';
      const installmentValue = '0';
      const recoupmenttypeCode = 'N';
      const frequency = optSelectOne;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = 'Y';
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const startDate = values.startDateStr;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty,
        startDateStr: startDate
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));

      setSelectedRecoupmentDate(null);
    } else {
      const percentValue = retainValues.percentValue;
      const startDate = retainValues.startDateStr;
      const installmentValue = retainValues.installmentValue;
      const recoupmenttypeCode = retainValues.recoupmentTypeCode;
      const frequency = retainValues.frequency;
      const amount = retainValues.amount;
      const defaultOnPaymentSchedule = retainValues.defaultOnPaymentSchedule;
      const underAppeal = 'N';
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty,
        startDateStr: startDate
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));
    }
  }, [values.underAppeal]);

  useEffect(() => {
    if (
      transferDetails &&
      transferDetails.transferVO !== null &&
      transferDetails.transferVO !== undefined
    ) {
      claimsData.transferVO = transferDetails.transferVO;
      props.history.push({
        pathname: '/ARTransfer',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (transferDetails && transferDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      transferDetails &&
      transferDetails.transferVO === null &&
      transferDetails.errorMessgaes &&
      transferDetails.errorMessgaes !== null &&
      transferDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(transferDetails.errorMessgaes);
    }
  }, [transferDetails]);
  useEffect(() => {
    if (
      reverseDetails &&
      reverseDetails.reversalVO !== null &&
      reverseDetails.reversalVO !== undefined
    ) {
      claimsData.reversalVO = reverseDetails.reversalVO;
      props.history.push({
        pathname: '/ARReverse',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (reverseDetails && reverseDetails.reversalVO === null) {
      seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
    }
    if (reverseDetails && reverseDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      reverseDetails &&
      reverseDetails.reversalVO === null &&
      reverseDetails.errorMessgaes &&
      reverseDetails.errorMessgaes !== null &&
      reverseDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(reverseDetails.errorMessgaes);
    }
  }, [reverseDetails]);
  useEffect(() => {
    if (
      adjustDetails &&
      adjustDetails.adjustmentVO !== null &&
      adjustDetails.adjustmentVO !== undefined
    ) {
      claimsData.adjustmentVO = adjustDetails.adjustmentVO;
      props.history.push({
        pathname: '/ARAdjust',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (adjustDetails && adjustDetails.SystemError === true) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
    if (
      adjustDetails &&
      adjustDetails.adjustmentVO === null &&
      adjustDetails.errorMessgaes &&
      adjustDetails.errorMessgaes !== null &&
      adjustDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(adjustDetails.errorMessgaes);
    }
  }, [adjustDetails]);

  function ARAdjust() {
    checkValidationLinks();
    if (values.recoupmentTypeCode === 'S') {
      if (paymentScheduleData.length === 0) {
        errorMessagesArray.push(ErrorConstants.PAYMENT_SCHEDULE_ERROR);
      }
    }
    if (errorMessagesArray.length === 0) {
      if (
        grossLevelDetails.balanceAmount === '0' ||
        grossLevelDetails.balanceAmount === '0.0' ||
        grossLevelDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.ADJUSTED_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
        claimsData.recoupmentVO.startDateStr = selectedRecoupmentDate
          ? getDateInMMDDYYYYFormatWithApendZero(selectedRecoupmentDate)
          : null;
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARAdjust(claimsData);
      }
    }
  }
  function ARReverse() {
    checkValidationLinks();
    if (values.recoupmentTypeCode === 'S') {
      if (paymentScheduleData.length === 0) {
        errorMessagesArray.push(ErrorConstants.PAYMENT_SCHEDULE_ERROR);
      }
    }
    if (errorMessagesArray.length === 0) {
      claimsData.accountVO.scoaFiscalYear = values.fiscalYear;

      claimsData.recoupmentVO.startDateStr = selectedRecoupmentDate
        ? getDateInMMDDYYYYFormatWithApendZero(selectedRecoupmentDate)
        : null;
      claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
        '-'
      )[0];
      claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
        '-'
      )[0];
      claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
      const adjustedAmount = parseAmount(claimsData.claimsReceivableDetailsVO.adjustedArAmt);
      const originalAmount = parseAmount(claimsData.claimsReceivableDetailsVO.originalAmount);
      const balanceAmount = parseAmount(claimsData.claimsReceivableDetailsVO.balanceAmount);
      const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
      if (balanceAmount === amountToUse) {
        checkARREverse(claimsData);
      } else {
        seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
      }
    }
  }
  function ARTransfer() {
    checkValidationLinks();
    if (values.recoupmentTypeCode === 'S') {
      if (paymentScheduleData.length === 0) {
        errorMessagesArray.push(ErrorConstants.PAYMENT_SCHEDULE_ERROR);
      }
    }
    if (errorMessagesArray.length === 0) {
      if (
        grossLevelDetails.balanceAmount === '0' ||
        grossLevelDetails.balanceAmount === '0.0' ||
        grossLevelDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.TRANSFER_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        seterrorMessages([]);
        setSystemSuccesMessages([]);

        claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
        claimsData.recoupmentVO.startDateStr = selectedRecoupmentDate
          ? getDateInMMDDYYYYFormatWithApendZero(selectedRecoupmentDate)
          : null;
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARTransfer(claimsData);
      }
    }
  }
  function setInputValues(grossLevelDetails, values, recoupmentsection) {
    if (
      grossLevelDetails.accountReceivableReasonCode != null &&
      grossLevelDetails.accountReceivableReasonCode !== undefined
    ) {
      for (var key in reasonCodeMapArray) {
        if (
          reasonCodeMapArray[key].code ===
          grossLevelDetails.accountReceivableReasonCode
        ) {
          grossLevelDetails.accountReceivableReasonCode =
            reasonCodeMapArray[key].description;
        }
      }
    }

    moment(grossLevelDetails.establishedDate).format(newDate);
    moment(grossLevelDetails.startDateStr).format(newDate);
    return {
      lob: AppConstants.DEFAULT_LOB_VALUE,
      payeeType: grossLevelDetails
        ? grossLevelDetails.payeePayerTypeCode
        : optSelectOne,
      payeeIdTypeCode: grossLevelDetails
        ? grossLevelDetails.payeePayerIDTypeCode
        : optSelectOne,
      reasonCode: grossLevelDetails.accountReceivableReasonCode
        ? grossLevelDetails.accountReceivableReasonCode
        : '',
      activityCode: grossLevelDetails
        ? grossLevelDetails.activityTypeCode
        : optSelectOne,
      systemPayeeId: grossLevelDetails ? grossLevelDetails.sysID : '',
      payeeId: grossLevelDetails ? grossLevelDetails.payeePayerID : '',
      payeePayerName: grossLevelDetails
        ? grossLevelDetails.payeePayerNameFromDB
        : '',
      fcn: grossLevelDetails ? grossLevelDetails.receivableFCN : '',
      payeeStatus: grossLevelDetails ? grossLevelDetails.payeeStatus : '',
      atrNumber: props?.location?.state?.claimsReceivableData?.atrNumber || '',
      adjustedARAmount: grossLevelDetails
        ? grossLevelDetails.adjustedArAmt
        : '',
      balanceAmount: grossLevelDetails ? grossLevelDetails.balanceAmount : '',
      approval: recoupmentsection.approval,
      relatedFTTCN: grossLevelDetails ? grossLevelDetails.relatedFTTCN : '',
      establishedDate: grossLevelDetails.establishedDateStr
        ? moment(grossLevelDetails.establishedDateStr).format(newDate)
        : '',
      lastDateApplied:
        grossLevelDetails && grossLevelDetails.lastAppliedDate
          ? moment(grossLevelDetails.lastAppliedDateStr).format(newDate)
          : null,
      arAgeInDays: grossLevelDetails ? grossLevelDetails.arAgeInDays : null,
      underAppeal: recoupmentsection.underAppeal,
      arLettersent: grossLevelDetails ? grossLevelDetails.agingCode : '',
      categoryOfServiceData: COSData || [],
      categoryOfService: grossLevelDetails.accountVO
        ? grossLevelDetails.accountVO.cos
        : optSelectOne,
      exemptFromCollectionAgency: recoupmentsection.exemptFromCollectionAgency,
      exemptFromInterest: recoupmentsection.exemptFromInterest,
      exemptFromPenalty: recoupmentsection.exemptFromPenalty,
      collocationCodeData: fundCodeData || [],
      collocationCode: grossLevelDetails.accountVO
        ? grossLevelDetails.accountVO.fundCode
        : values.collocationCode,
      recoupmentData: recoupmentData,
      payeePaymentStatusFrmState: payeePaymentStatusFrmState,
      frequencyData: frequencyData,
      recoupmentTypeCode: recoupmentsection.recoupment,
      frequency: recoupmentsection.frequency
        ? recoupmentsection.frequency
        : optSelectOne,
      amountDue: recoupmentsection.amount,
      percentValue: recoupmentsection.percent
        ? numeral(recoupmentsection.percent).format('0.00')
        : '',
      installmentValue: recoupmentsection.installment
        ? numeral(recoupmentsection.installment).format('0,0.00')
        : recoupmentsection.installment,
      defaultOnPaymentSchedule: recoupmentsection.defaultOnPaymentSchedule,
      originalARAmountData: grossLevelDetails
        ? grossLevelDetails.originalAmount
        : 0,
      balanceAmountData: grossLevelDetails
        ? grossLevelDetails.balanceAmount
        : 0,
      appliedAmountData:
        grossLevelDetails &&
          grossLevelDetails.historyVO &&
          grossLevelDetails.historyVO.appliedAmount
          ? grossLevelDetails.historyVO.appliedAmount
          : 0,
      fiscalYear: grossLevelDetails.accountVO
        ? grossLevelDetails.accountVO.scoaFiscalYear
        : values.fiscalYear,
      originalARAmount: grossLevelDetails
        ? amountFormatCheck(grossLevelDetails.originalAmount)
        : 0,
      paymentScheduleType: values.paymentScheduleType,
      paymentScheduleFrequency: values.paymentScheduleFrequency,
      recoupmentStartDate:  values.recoupmentStartDate,
      numberofInstallments: values.numberofInstallments,
      interestCalculation: values.interestCalculation,
      interestPercentage: values.interestPercentage,
      recoupedAmount: values.recoupedAmount,
      installmentAmount: values.installmentValue,
      federal:
        grossLevelDetails && grossLevelDetails.fundSourceAllocationVO
          ? numeral(
            grossLevelDetails.fundSourceAllocationVO.federalPercentage
          ).format('0.00')
          : '',
      state:
        grossLevelDetails && grossLevelDetails.fundSourceAllocationVO
          ? numeral(
            grossLevelDetails.fundSourceAllocationVO.statePercentage
          ).format('0.00')
          : '',
      county:
        grossLevelDetails && grossLevelDetails.fundSourceAllocationVO
          ? numeral(
            grossLevelDetails.fundSourceAllocationVO.countyPercentage
          ).format('0.00')
          : '',
      other:
        grossLevelDetails && grossLevelDetails.fundSourceAllocationVO
          ? numeral(
            grossLevelDetails.fundSourceAllocationVO.otherPercentage
          ).format('0.00')
          : ''
    };
  }

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedRecoupmentDate, setSelectedRecoupmentDate] = React.useState(
    null
  );
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [selectedDefaultDate, setSelectedDefaultDate] = React.useState(null);
  const [paymentScheduleData, setPaymentSchedleData] = React.useState(
    scheduleData
  );

  useEffect(() => {
    if (paymentScheduleData && paymentScheduleData.length > 0) {
      paymentScheduleData.map(value => {
        if (
          value.recoupedAmount &&
          numeral(value.recoupedAmount).format('0.00') !== '0.00'
        ) {
          setManualEditFlag(true);
        }
      });
    }
  }, [paymentScheduleData]);

  const [grossLevel, setGrossLevel] = React.useState({});

  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setSelectedBeginDate(date);
    setAllowNavigation(true);
  };

  const handleEndDateChange = date => {
    setFocusCheck(true);
    setSelectedEndDate(date);
    setAllowNavigation(true);
  };

  const handleRecoupmentDateChange = date => {
    setSelectedRecoupmentDate(date);
    setAllowNavigation(true);
  };

  const handleDueDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDueDate(date);
  };

  const handleDefaultDate = date => {
    setFocusCheck(true);
    setSelectedDefaultDate(date);
  };
  const [manualEditFlag, setManualEditFlag] = React.useState(false);

  const setInput = grossLevelDetails => {
    const percentValue =
      grossLevelDetails.recoupmentVO &&
        grossLevelDetails.recoupmentVO.recoupmentTypeCode !== 'S'
        ? grossLevelDetails.recoupmentVO.percentageOriginalAmount
        : null;
    const startDate =
      grossLevelDetails.recoupmentVO
        ? grossLevelDetails.recoupmentVO.startDateStr
        : null;
    const installmentValue = grossLevelDetails.recoupmentVO
      ? grossLevelDetails.recoupmentVO.installmentAmount
      : null;
    const recoupmenttypeCode = grossLevelDetails.recoupmentVO
      ? grossLevelDetails.recoupmentVO.recoupmentTypeCode
      : null;
    const frequency = grossLevelDetails.recoupmentVO
      ? grossLevelDetails.recoupmentVO.frequency
      : null;
    const amount = retainDueAmout;
    const defaultOnPaymentSchedule = values.defaultOnPaymentSchedule;
    const underAppeal = grossLevelDetails.grossLevelReceivableVO
      ? grossLevelDetails.grossLevelReceivableVO.appealIndicator === 0 ||
        grossLevelDetails.grossLevelReceivableVO.appealIndicator === '0'
        ? 'N'
        : 'Y'
      : 'N';
    const approval = grossLevelDetails
      ? grossLevelDetails.approvalIndicator === '0'
        ? 'N'
        : 'Y'
      : 'N';
    const exemptFromCollectionAgency = grossLevelDetails.exemptionsVO
      ? grossLevelDetails.exemptionsVO.collectionAgencyInd === '1' ||
        grossLevelDetails.exemptionsVO.collectionAgencyInd === 1
        ? 'Y'
        : 'N'
      : 'N';
    const exemptFromInterest = grossLevelDetails.exemptionsVO
      ? grossLevelDetails.exemptionsVO.interestExemptionsInd === '1' ||
        grossLevelDetails.exemptionsVO.interestExemptionsInd === 1
        ? 'Y'
        : 'N'
      : 'N';
    const exemptFromPenalty = grossLevelDetails.exemptionsVO
      ? grossLevelDetails.exemptionsVO.penaltyExemptionsInd === '1' ||
        grossLevelDetails.exemptionsVO.penaltyExemptionsInd === 1
        ? 'Y'
        : 'N'
      : 'N';
    const recoupmentsection = {
      percent: percentValue,
      installment: installmentValue,
      recoupment: recoupmenttypeCode,
      frequency: frequency,
      amount: amount,
      defaultOnPaymentSchedule: defaultOnPaymentSchedule,
      underAppeal: underAppeal,
      approval: approval,
      exemptFromCollectionAgency: exemptFromCollectionAgency,
      exemptFromInterest: exemptFromInterest,
      exemptFromPenalty: exemptFromPenalty,
      startDateStr: startDate
    };
    setValues(
      setInputValues(
        grossLevelDetails,
        {
          ...values,
          paymentScheduleType: grossLevelDetails.paymentScheduleType
            ? grossLevelDetails.paymentScheduleType
            : optSelectOne,
          recoupmentStartDate: grossLevelDetails.recoupmentStartDate
            ? grossLevelDetails.recoupmentStartDate
            : null,
          numberofInstallments: grossLevelDetails.numberofInstallments
            ? grossLevelDetails.numberofInstallments
            : '',
          interestCalculation: grossLevelDetails.interestCalculation
            ? grossLevelDetails.interestCalculation
            : 'N',
          interestPercentage: grossLevelDetails.interestPercentage
            ? numeral(grossLevelDetails.interestPercentage).format('0,0.00')
            : '',
          paymentScheduleFrequency: grossLevelDetails.paymentScheduleFrequency
            ? grossLevelDetails.paymentScheduleFrequency
            : optSelectOne
        },
        recoupmentsection
      )
    );
    if (
      grossLevelDetails.recoupmentVO &&
      (grossLevelDetails.recoupmentVO.recoupmentTypeCode === 'N' ||
        grossLevelDetails.recoupmentVO.recoupmentTypeCode === 'S')
    ) {
      setSelectedRecoupmentDate(null);
    } else {
      setSelectedRecoupmentDate(
        grossLevelDetails.recoupmentVO &&
          grossLevelDetails.recoupmentVO.startDateStr
          ? grossLevelDetails.recoupmentVO.startDateStr
          : null
      );
    }

    setSelectedBeginDate(
      grossLevelDetails.grossLevelReceivableVO
        ? grossLevelDetails.grossLevelReceivableVO.beginDateStr
        : null
    );
    setSelectedEndDate(
      grossLevelDetails.grossLevelReceivableVO
        ? grossLevelDetails.grossLevelReceivableVO.endDateStr
        : null
    );

    setSelectedRecoupmentStartDate(
      grossLevelDetails && grossLevelDetails.recoupmentStartDate
        ? grossLevelDetails.recoupmentStartDate
        : null
    );
    setRetainValues(values);
  };
  useEffect(() => {
    onDropdowns([
     Dropdowns.PAYEE_TYPE,
     Dropdowns.ACTIVITY_TYPE,
     Dropdowns.F_RSN_CD,
     Dropdowns.RECOUPMENT,
     Dropdowns.FUND_CODE,
     Dropdowns.PAY_STATUS,
     Dropdowns.COS,
     Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
     Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
     Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
     Dropdowns.PAYEE_ID_TYPE_CD_TPL]);
  }, []);
  // useEffect for the "Use Payee Default Recoupment" service
  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && !recoupmentTypeClicked
    ) {
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount
          ? numeral(getRecoupmetDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(getRecoupmetDetails.recoupmentVO.startDateStr);

      seterrorMessages([]);
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);
  const systemParamValuetoPercentage = {
    parameterNumber: '9005',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  const handleClickOpenPaymentSchedule = () => {
    const errorArray = [];
    if (values.paymentScheduleType === optSelectOne) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setPopUpErrorMessage([]);
      setSelectedDueDate(null);
      setSelectedDefaultDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText('');
      setPaymentScheduleVO({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainPaymentSchedule({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError('');
      setPaymentScheduleTypeError('');
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleClose = () => {
    setAuditLogRow(null);
    setOpen(false);
    setPaymentScheduleVO({
      installmentAmount: '',
      recoupedAmount: '0.00'
    });
    seterrorMessages([]);
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
    if (newValue === 3) {
      setTabFour(true);
    }
    const colorCondition = checkFormValidations();
    if (newValue > 0) {
      if (colorCondition) {
        if (errorMessagesArray.length > 0) {
          setColor('red');
        } else {
          setColor('green');
        }
      }
    }
  };

  const savePaymentSchedule = () => {
    var selectedDueDateError;
    var amountDueError = false;
    var selectedDueDateGreaterErrorTemp = false;
    var minDueDateError = false;
    var minDefaultDateError = false;
    var invalidDueDateError = false;
    var invalidDefaultDateError = false;
    var negativeAmountError = false;
    var amountGreaterThanZero = false;
    var originalArAmountRequired = false;
    var selectedDueDateErrorText;
    var amountDueErrorText = '';
    var selectedDueDateErrorTextTemp = '';
    var minDueDateErrorText = '';
    var minDefaultDateErrorText = '';
    var invalidDueDateErrorText = '';
    var invalidDefaultDateErrorText = '';
    var negativeAmountErrorText = '';
    var amountGreaterThanZeroText = '';
    var originalArAmountRequiredText = '';
    const installmentValue = parseAmount(paymentScheduleVO.installmentAmount);
    const { amountToUse } = getAmountToUse();

    if (!selectedDueDate) {
      selectedDueDateError = true;
      selectedDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        invalidDueDateError = true;
        invalidDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDueDateErrorText);
      }
      if (!invalidDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          minDueDateError = true;
          minDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(minDueDateErrorText);
        }
      }
    }

    if (selectedDefaultDate) {
      if (!moment(selectedDefaultDate).isValid()) {
        invalidDefaultDateError = true;
        invalidDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDefaultDateErrorText);
      }

      if (!invalidDefaultDateError) {
        if (selectedDefaultDate) {
          if (validateDateMinimumValue(selectedDefaultDate)) {
            minDefaultDateError = true;
            minDefaultDateErrorText = AppConstants.DATE_ERROR_1964;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          } else if (
            !compareTwoDates(
              new Date(
                new Date().toLocaleString('en-US', {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              ),
              selectedDefaultDate
            )
          ) {
            minDefaultDateError = true;
            minDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_GT_TODAY;
            popUpErrorMessagesArray.push(invalidDefaultDateErrorText);
          }
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (paymentScheduleVO.installmentAmount) {
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        popUpErrorMessagesArray.push(originalArAmountRequiredText);
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        } else {
          if (
            !(
              paymentScheduleVO.installmentAmount
                .toString()
                .replace(/,/g, '')
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            negativeAmountError = true;
            negativeAmountErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(negativeAmountErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountGreaterThanZero = true;
          amountGreaterThanZeroText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountGreaterThanZeroText);
        }
        if (installmentValue > amountToUse) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    } else {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }

    setPopUpErrorMessage(popUpErrorMessagesArray);
    seterrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: selectedDueDateError,
      showAmountDueError: amountDueError,
      showDueDateGreaterError: selectedDueDateGreaterErrorTemp,
      showMinDueDateError: minDueDateError,
      showMinDefaultDateError: minDefaultDateError,
      showDueDateInvalidError: invalidDueDateError,
      showDefaultDateError: invalidDefaultDateError,
      showNegativeAmountError: negativeAmountError,
      showGreaterThanZeroAmountError: amountGreaterThanZero,
      showOriginalArAmountPopError: originalArAmountRequired
    });
    setShowErrorText({
      showDueDateErrorText: selectedDueDateErrorText,
      showAmountDueErrorText: amountDueErrorText,
      showDueDateGreaterErrorText: selectedDueDateErrorTextTemp,
      showMinDueDateErrorText: minDueDateErrorText,
      showMinDefaultDateErrorText: minDefaultDateErrorText,
      showDueDateInvalidErrorText: invalidDueDateErrorText,
      showDefaultDUeDateErrorText: invalidDefaultDateErrorText,
      showNegativeAmmountErrorText: negativeAmountErrorText,
      showGreaterThanZeroAmountText: amountGreaterThanZeroText,
      showOriginalArAmountPopErrorText: originalArAmountRequiredText
    });
    if (add) {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          overlapId: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format(newDate)
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let duplicateDueDate = false;
          scheduleData.map((option, index) => {
            if (option.dueDate === value.dueDate) {
              const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
              setShowError({
                showDueDateError: true
              });
              setShowErrorText({
                showDueDateErrorText: duplicateMessage
              });
              duplicateDueDate = true;
              window.scrollTo(0, 0);
            }
          });
          if (!duplicateDueDate) {
            scheduleData.push(value);
            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(value);
          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      if (popUpErrorMessagesArray.length === 0) {
        const scheduleData = paymentScheduleData;
        if (scheduleData.length > 0) {
          let duplicateDueDate = false;
          scheduleData.map((option, index) => {
            if (
              option.dueDate ===
              moment(new Date(selectedDueDate)).format('L') &&
              option.overlapId !== paymentScheduleVO.overlapId
            ) {
              const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
              setShowError({
                showDueDateError: true
              });
              setShowErrorText({
                showDueDateErrorText: duplicateMessage
              });
              duplicateDueDate = true;
              window.scrollTo(0, 0);
            }
          });
          if (!duplicateDueDate) {
            paymentScheduleData.map(value => {
              if (paymentScheduleVO.id && value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    newDate
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              if (
                paymentScheduleVO.scheduleSK &&
                value.scheduleSK === paymentScheduleVO.scheduleSK
              ) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    newDate
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
              handleClose();
            });
          }
        }
      }
    }
  };

  const resetPaymentSchedule = () => {
    setPopUpErrorMessage([]);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText('');
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };
  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = '';
    let paymentScheduleTypeText = '';
    let recoupmentStartDateText = '';
    let noOfInstallmentText = '';
    let frequencyText = '';
    let calPercentText = '';
    setPaymentScheduleTypeError('');
    setRecoupmentStartDateError('');
    setFrequencyError('');
    setNumberofinstallmentError('');
    setInstallmentCalError('');
    if (values.paymentScheduleType === optSelectOne) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (
      values.adjustedARAmount === '' ||
      values.adjustedARAmount === undefined
    ) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === optSelectOne) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === '') {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== '') {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.';
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.'
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.'
        );
      }
    }
    if (
      values.interestCalculation === 'Y' &&
      values.interestPercentage === ''
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage &&
      values.interestPercentage !== '' &&
      values.interestPercentage
        .toString()
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
      values.paymentScheduleType === 'A'
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
          Number(values.numberofInstallments)
        )
      ).format('0.00');
      const numberOfRows = Number(
        numeral(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
          Number(amountStep)
        ).format('0')
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case '2':
          daysStep = 7;
          break;
        case '3':
          daysStep = 14;
          break;
        case '4':
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              newDate
            ),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format(newDate),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format('0.00')) +
          Number(numeral(amountStep).format('0.00'))
        ).format('0.00');
        scheduleArray.push(value);
      }
      if (
        numeral(values.balanceAmount).format('0.00') !==
        numeral(sumOfInstallmentAmount).format('0.00')
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              '0.00'
            )
          ) +
          (parseFloat(numeral(values.balanceAmount).format('0.00')) -
            parseFloat(numeral(sumOfInstallmentAmount).format('0.00')))
        ).format('0,0.00');
      }
      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
      seterrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      seterrorMessages(errorArray);
    }
  };
  function PaymentRowDeleteClick() {
    setAllowNavigation(true);
    let temNewDialogData = [...paymentScheduleData];
    const delDataTemp = deleteSchedulePaymentList;
    let delData = [];
    for (
      let i = 0;
      i < rowPaymentScheduleData.rowPaymentScheduleData.length;
      i++
    ) {
      delData = temNewDialogData.filter(
        payment =>
          payment.id === rowPaymentScheduleData.rowPaymentScheduleData[i] &&
          payment.paymentScheduleKey
      );
      delDataTemp.push(...delData);
      temNewDialogData = temNewDialogData.filter(
        payment =>
          payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
      );
    }
    const mappedData = {};
    for (let i = 0; i < delData.length; i++) {
      if (delData[i].paymentScheduleKey) {
        for (var key in grossLevelDetails.schedulePaymentMap) {
          if (key !== delData[i].paymentScheduleKey) {
            Object.defineProperty(mappedData, key, {
              value: grossLevelDetails.schedulePaymentMap[key],
              writable: false
            });
          }
        }
      }
    }
    setPaymentSchedleData(temNewDialogData);
    setRowPaymentScheduleData([]);
    setDeleteSchedulePaymentList(delDataTemp);
    setSchedulePaymentMap(mappedData);
  }

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (
      name === 'paymentScheduleType' &&
      values.paymentScheduleType !== event.target.value
    ) {
      setPaymentScheduleChanged(generateUUID());
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
    }
    if (
      name === 'numberofInstallments' ||
      name === 'paymentScheduleFrequency'
    ) {
      setPaymentSettingChanged(generateUUID());
    }
    if (name === 'interestCalculation') {
      setValues({
        ...values,
        [name]: event.target.value,
        interestPercentage: ''
      });
    }
    else if (name === 'recoupmentTypeCode') {
      setRecoupmentTypeClicked(true);
      setShowErrorText('');
      if (event.target.value === 'N') {
        const percentValue = '0';
        const installmentValue = '';
        const recoupmentTypeCode = 'N';
        const frequency = optSelectOne;
        const amount = '';
        const recoupmentsection = {
          percent: percentValue,
          installment: installmentValue
            ? numeral(installmentValue).format('0,0.00')
            : installmentValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
		  underAppeal:values.underAppeal,
          approval:values.approval
        };
        setValues(setInputValues(grossLevelDetails, {
          ...values,
          paymentScheduleType: optSelectOne,
          recoupmentStartDate: '',
          numberofInstallments: '',
          interestCalculation: 'N',
          interestPercentage: '',
          paymentScheduleFrequency: optSelectOne
        }, recoupmentsection));
        setSelectedRecoupmentDate(null);
        setPaymentSchedleData([]);
      }
      if (event.target.value === 'S') {
        setSelectedRecoupmentDate(null);
        setValues({
          ...values,
          recoupmentTypeCode: event.target.value,
          installmentValue: ''
        })
      }
      if (event.target.value === 'A') {
        const percentValue = '';
        let installmentValue = '0';
        const recoupmentTypeCode = 'A';
        const frequency = '1';
        const amount = '';
		const underAppeal = values.underAppeal;
        if (
          checkValueExists(dropDownCheckList, values.reasonCode)
        ) {
          dispatch(
            getSystemParameterSpecificValue({
              paramNumber: 9010,
              functionalArea: 'F1',
              lobCode: AppConstants.DEFAULT_LOB_VALUE,
              asOfDate: moment(
                new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: process.env.REACT_APP_TIMEZONE
                  })
                )
              ).format('YYYY-DD-MM')
            })
          );
          installmentValue = '';
        }
        setPaymentSchedleData([]);
        const recoupmentsection = {
          percent: percentValue,
          installment: installmentValue
            ? numeral(installmentValue).format('0,0.00')
            : installmentValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
		  underAppeal:underAppeal,
          approval:values.approval
        };
        setValues(setInputValues(grossLevelDetails, {
          ...values,
          installmentValue: '',
          paymentScheduleType: optSelectOne,
          recoupmentStartDate: '',
          numberofInstallments: '',
          interestCalculation: 'N',
          interestPercentage: '',
          paymentScheduleFrequency: optSelectOne
        }, recoupmentsection));
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (event.target.value === 'B') {
        let percentValue = '100';
        const installmentValue = '';
        const recoupmentTypeCode = 'B';
        const frequency = '1';
        const amount = '';
        if (
          checkValueExists(dropDownCheckList, values.reasonCode)
        ) {
          dispatch(
            getSystemParameterSpecificValue({
              paramNumber: 9011,
              functionalArea: 'F1',
              lobCode: AppConstants.DEFAULT_LOB_VALUE,
              asOfDate: moment(
                new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: process.env.REACT_APP_TIMEZONE
                  })
                )
              ).format('YYYY-DD-MM')
            })
          );
          percentValue = '';
        }
        const recoupmentsection = {
          percent: percentValue,
          installment: installmentValue
            ? numeral(installmentValue).format('0,0.00')
            : installmentValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
		  underAppeal:values.underAppeal,
          approval:values.approval
        };
        setPaymentSchedleData([]);
        setValues(setInputValues(grossLevelDetails, {
          ...values,
          paymentScheduleType: optSelectOne,
          recoupmentStartDate: '',
          numberofInstallments: '',
          interestCalculation: 'N',
          interestPercentage: '',
          paymentScheduleFrequency: optSelectOne
        }, recoupmentsection));
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (event.target.value === 'D') {
        setPaymentSchedleData([]);
        setValues({
          ...values,
          installmentValue: '',
          recoupmentStartDate: '',
          numberofInstallments: '',
          interestCalculation: 'N',
          interestPercentage: '',
          paymentScheduleFrequency: optSelectOne,
          paymentScheduleType: optSelectOne
        }
        );
        searchSystemParameterPercentage(systemParamValuetoPercentage);
        if (recoupmentTypeClicked) {
          onGetRecoupmetDetails(claimsData);
        }
      }
    }
	else if (name === 'frequency'){
		setValues({ ...values, [name]: event.target.value });
	}
  else {
    setValues({ ...values, [name]: event.target.value });
  }
  };

  const handleDecimalValues = name => event => {
    if (name === 'installmentValue') {
      setFocusCheck(true);
      setAllowNavigation(true);
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
        }
  }
  else {
    setValues({ ...values, [name]: event.target.value });
    }
};
  const formatDecimalField = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0,0.00')
      });
    }
  };

  const formatPercent = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0.00')
      });
    }
  };

  const removeFormatDecimalFieldInstallment = name => event => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format('0.00')
      });
    }
  };

  const removeFormatDecimalFieldPercent = name => event => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format('0.00')
      });
    }
  };

  const formatDecimalFieldInstallment = name => event => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0,0.00'
        )
      });
    }
  };

  const removeFormatDecimalFieldAmountDue = name => event => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0.00'
        )
      });
    }
  };
  const formatAmountDue = name => event => {
    if (retainDueAmout) {
      setREtainDueAmout(numeral(event.target.value).format('0,0.00'));
    }
  };

  var showOriginalArAmmountErrorTemp = false;
  var showApprovalErrorTemp = false;
  var showFiscalYearErrorTemp = false;
  var showCollocationCodeErrorTemp = false;
  var showRecoupmentTypeCodeErrorTemp = false;
  var showInvalidBeginDateErrorTemp = false;
  var showInvalidEndDateErrorTemp;
  var showEndDateLesserErrorTemp;
  var showBeginDateRquiredErrorTemp = false;
  var showEndDateRequiredErrorTemp = false;
  var showPercentErrorTemp = false;
  var showInstallmentErrorTemp = false;
  var startDateErrorTemp = false;
  var startDateReqErrorTemp = false;
  var minimumBeginDateError = false;
  var minimumEndDateError = false;
  var minimumRecoupmentDaateError = false;
  var minimumDueDateError = false;
  var minimumDefaultDateError = false;
  var showATRErrorTemp = false;
  var showOriginalArAmountErrorTextTemp = '';
  var showATRErrorTextTemp = '';
  var showApprovalErrorTextTemp = '';
  var showFiscalYearErrorTextTemp = '';
  var showCollocationCodeErrorTextTemp = '';
  var showRecoupmentTypeCodeErrorTextTemp = '';
  var showInvalidBeginDateTextTemp = '';
  var showInvalidEndDateInvalidTextTemp = '';
  var showEndDateLesserErrorTextTemp = '';
  var showBeginDateRequiredErrorTextTemp = '';
  var showEndDateRequiredErrorTextTemp = '';
  var minBeginDateTextTemp = '';
  var minEndDateTextTemp = '';
  var minRecoupmentDateTextTemp = '';
  var minDueDateTextTemp = '';
  var minDefaultDateTextTemp = '';
  var showPercentErrorTextTemp = '';
  var showInstallmentErrorTextTemp = '';
  var showStartDateErrorTextTemp = '';
  var startDateReqErrorTextTemp = '';

  const checkFormValidations = () => {
    const regex = /[^A-Za-z0-9]/ ;
    const { balanceAmount, installmentValue, amountToUse } = getAmountToUse();
    var showFrequencyErrorText = '';
    var showFrequencyError = false;
    var calPercentText = '';
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }
    if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRErrorTemp = true;
      showATRErrorTextTemp = (`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`);
      errorMessagesArray.push(showATRErrorTextTemp);
    } else {showATRErrorTemp = false;}
    if (paymentScheduleData?.length > 0 && values.recoupmentTypeCode === 'S') {
      let summation = 0;
      if (values.paymentScheduleType === 'M') {
        paymentScheduleData.map(value => {
          summation =
            summation + Number(numeral(value.installmentAmount).format('0.00'));
        });
        const summationAmount = parseAmount(summation);
        if (summationAmount !== amountToUse) {
          errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
        }
      }
    }

    if (values.fiscalYear === '' || values.fiscalYear === undefined) {
      if (selectedBeginDate) {
        setValues({ ...values, fiscalYear: selectedBeginDate.getFullYear() });
      } else {
        showFiscalYearErrorTemp = true;
        showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
        errorMessagesArray.push(showFiscalYearErrorTextTemp);
      }
    }
    if (
      values.collocationCode === '' ||
      values.collocationCode === optSelectOne ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }
    if (values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT ||
      values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT){
      if (values.frequency === undefined ||
      values.frequency === DEFAULT_DD_VALUE
      ){
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }
    if (values.recoupmentTypeCode === AppConstants.RECOUPMENT_SCHEDULE_PAYMENT) {
      if (amountToUse !== balanceAmount) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPMENT_BALANCE_INVALID;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
      }
    if (values.underAppeal === 'N') {
      if (
        values.recoupmentTypeCode === '' ||
        values.recoupmentTypeCode === optSelectOne ||
        values.recoupmentTypeCode === undefined
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }
    if (
      grossLevelDetails.recoupmentVO?.recoupmentTypeCode === 'S' ||
      grossLevelDetails.recoupmentVO?.recoupmentTypeCode === 'N'
    ) {
      if (
        !(
          values.recoupmentTypeCode === 'S' || values.recoupmentTypeCode === 'N'
        ) &&
        paymentScheduleData.length > 0
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPEMENT_INVALID;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }
    if (
      !(
        grossLevel.recoupmentVO?.recoupmentTypeCode === 'S' ||
        grossLevel.recoupmentVO?.recoupmentTypeCode === 'N'
      ) &&
      values.recoupmentTypeCode !== optSelectOne
    ) {
      if (
        values.recoupmentTypeCode === 'S' ||
        values.recoupmentTypeCode === 'N'
      ) {
        const ARReasonCode = grossLevelDetails.accountReceivableReasonCode?.split("-")[0];
        if (
          adjustedReasonCode.some(
            values => values.code === ARReasonCode
          )
        ) {
          if (amountToUse !== balanceAmount) {
            showRecoupmentTypeCodeErrorTemp = true;
            showRecoupmentTypeCodeErrorTextTemp =
              ErrorConstants.RECOUPMENT_BALANCE_INVALID;
            errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
          }
        }
      }
    }
    if (
      !showRecoupmentTypeCodeErrorTemp &&
      !(values.recoupmentTypeCode === 'S' || values.recoupmentTypeCode === 'N')
    ) {
      if (
        values.paymentScheduleType ||
        values.paymentScheduleFrequency ||
        values.interestCalculation === 'Y' ||
        values.numberofInstallments ||
        values.interestPercentage || values.recoupmentStartDate
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPEMENT_INVALID;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }

    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
      if (!showInvalidBeginDateErrorTemp) {
        if (validateDateMinimumValue(selectedBeginDate)) {
          minimumBeginDateError = true;
          minBeginDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minBeginDateTextTemp);
        }
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.END_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
      if (!showInvalidEndDateErrorTemp) {
        if (validateDateMinimumValue(selectedEndDate)) {
          minimumEndDateError = true;
          minEndDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minEndDateTextTemp);
        }
      }
    }

    if (
      selectedBeginDate &&
      selectedEndDate &&
      !minimumBeginDateError &&
      !showInvalidBeginDateErrorTemp &&
      !minimumEndDateError &&
      !showInvalidEndDateErrorTemp &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedEndDate),
        new Date(selectedBeginDate)
      )
    ) {
      showEndDateLesserErrorTemp = true;
      showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
      errorMessagesArray.push(showEndDateLesserErrorTextTemp);
    }

    let pushFlag = false;
    if (reasonCd?.includes(values.reasonCode?.split("-")[0])){
      if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.REASON_CODE_SETTLEMENT_ERROR;
		pushFlag = true;
      }
      if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.REASON_CODE_SETTLEMENT_ERROR;
		pushFlag = true;
      }
	  if (pushFlag){
		errorMessagesArray.push(ErrorConstants.REASON_CODE_SETTLEMENT_ERROR);
	  }

    }

    if (values.percentValue && parseFloat(values.percentValue) < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.NEGATIVE_PERCENT;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== 'N' &&
      values.percentValue &&
      parseFloat(values.percentValue) === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && parseFloat(values.percentValue) > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (installmentValue > amountToUse) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_AMOUNT_INVALID;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }
    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.NEGATIVE_INSTALLMENT_VALUE;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    }

    if (values.recoupmentTypeCode === 'B') {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (values.installmentValue !== null && values.installmentValue !== '') {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }
    if (values.recoupmentTypeCode === 'D') {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (!values.installmentValue && !selectedRecoupmentDate) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_START_DATE_REQUIRED);
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }
    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'N') {
      if (
        values.installmentValue > 0 ||
        values.installmentValue < 0 ||
        values.percentValue > 0 ||
        values.percentValue < 0
      ) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
        if (values.percentValue !== '') {
          if (
            (values.percentValue !== '0' && values.percentValue !== '0.00') ||
            values.percentValue > 0 ||
            values.percentValue < 0
          ) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
        if (values.installmentValue !== '') {
          if (
            (values.installmentValue !== '0' &&
              values.installmentValue !== '0.00') ||
            values.installmentValue > 0 ||
            values.installmentValue < 0
          ) {
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
      }
      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }

      if (
        values.frequency &&
        values.frequency !== DEFAULT_DD_VALUE
      ) {
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY_MUST_BE_BLANK;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'B') {
      if (values.installmentValue !== null && values.installmentValue !== '') {
        if (!values.percentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (values.percentValue !== '' && values.percentValue !== null) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
        errorMessagesArray.push(showPercentErrorTextTemp);
        if (values.installmentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      } else if (
        validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      }
    }

    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showATRError: showATRErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyError
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showATRErrorText: showATRErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorText
    });
    seterrorMessages(errorMessagesArray);
    setErrorMessageCopy(errorMessagesArray);
    return true;
  };
  const checkValidationLinks = () => {
	const { installmentValue, amountToUse } = getAmountToUse();
    var showFrequencyErrorText = '';
    var showFrequencyError = false;
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }

    if (values.fiscalYear === '' || values.fiscalYear === undefined) {
      if (selectedBeginDate) {
        setValues({ ...values, fiscalYear: selectedBeginDate.getFullYear() });
      } else {
        showFiscalYearErrorTemp = true;
        showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
        errorMessagesArray.push(showFiscalYearErrorTextTemp);
      }
    }

    if (
      values.collocationCode === '' ||
      values.collocationCode === optSelectOne ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }

    if (values.underAppeal === 'N') {
      if (
        values.recoupmentTypeCode === '' ||
        values.recoupmentTypeCode === optSelectOne ||
        values.recoupmentTypeCode === undefined
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }

    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
      if (!showInvalidBeginDateErrorTemp) {
        if (validateDateMinimumValue(selectedBeginDate)) {
          minimumBeginDateError = true;
          minBeginDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minBeginDateTextTemp);
        }
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.END_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
      if (!showInvalidEndDateErrorTemp) {
        if (validateDateMinimumValue(selectedEndDate)) {
          minimumEndDateError = true;
          minEndDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minEndDateTextTemp);
        }
      }
    }

    if (
      selectedBeginDate &&
      selectedEndDate &&
      !minimumBeginDateError &&
      !showInvalidBeginDateErrorTemp &&
      !minimumEndDateError &&
      !showInvalidEndDateErrorTemp &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedEndDate),
        new Date(selectedBeginDate)
      )
    ) {
      showEndDateLesserErrorTemp = true;
      showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
      errorMessagesArray.push(showEndDateLesserErrorTextTemp);
    }

	if (reasonCd.includes(values.reasonCode.split("-")[0])){
      if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.BEGIN_DATE_REQUIRED;
        errorMessagesArray.push(showBeginDateRequiredErrorTextTemp);
      }
      if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.END_DATE_REQUIRED;
        errorMessagesArray.push(showEndDateRequiredErrorTextTemp);
      }

      if (selectedBeginDate) {
        if (!moment(selectedBeginDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
      }

      if (selectedEndDate) {
        if (!moment(selectedEndDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidEndDateInvalidTextTemp = ErrorConstants.END_DATE_INVALID;
          errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
        }
      }
    }

    if (values.percentValue && parseFloat(values.percentValue) < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.NEGATIVE_PERCENT;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== 'N' &&
      values.percentValue &&
      parseFloat(values.percentValue) === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && parseFloat(values.percentValue) > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (
     installmentValue > amountToUse
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_AMOUNT_INVALID;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }
    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.NEGATIVE_INSTALLMENT_VALUE;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    }

    if (values.recoupmentTypeCode === 'B') {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (values.installmentValue !== null && values.installmentValue !== '') {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (!values.installmentValue && !selectedRecoupmentDate) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_START_DATE_REQUIRED);
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }
    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'N') {
      if (
        values.installmentValue > 0 ||
        values.installmentValue < 0 ||
        values.percentValue > 0 ||
        values.percentValue < 0
      ) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
        if (values.percentValue !== '') {
          if (
            (values.percentValue !== '0' && values.percentValue !== '0.00') ||
            values.percentValue > 0 ||
            values.percentValue < 0
          ) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
        if (values.installmentValue !== '') {
          if (
            (values.installmentValue !== '0' &&
              values.installmentValue !== '0.00') ||
            values.installmentValue > 0 ||
            values.installmentValue < 0
          ) {
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
      }
      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }

      if (
        values.frequency &&
        values.frequency !== DEFAULT_DD_VALUE
      ) {
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY_MUST_BE_BLANK;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'B') {
      if (values.installmentValue !== null && values.installmentValue !== '') {
        if (!values.percentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (values.percentValue !== '' && values.percentValue !== null) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
        errorMessagesArray.push(showPercentErrorTextTemp);
        if (values.installmentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      } else if (
        validateDateMinimumValue(moment(startDate).format(newDate))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      }
    }

    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showATRError: showATRErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyError
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showATRErrorTemp: showATRErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorText
    });
    seterrorMessages(errorMessagesArray);
    setErrorMessageCopy(errorMessagesArray);
    return true;
  };
  const saveGrossLevelEdit = () => {

    let estDate = grossLevel ? grossLevel.establishedDate : null;
    estDate = new Date(estDate);
    const year = estDate.getFullYear();
    let date = estDate.getDate();
    let month = estDate.getMonth() + 1;
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }
    const estDateFormatted = `${year}-${month}-${date}`;
    checkFormValidations();
    if (values.recoupmentTypeCode === 'S') {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = '';
      let paymentScheduleTypeText = '';
      let recoupmentStartDateText = '';
      let noOfInstallmentText = '';
      let frequencyText = '';
      let calPercentText = '';
      if (values.paymentScheduleType === optSelectOne) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate && !manualEditFlag) {
        var startDate = new Date(selectedRecoupmentStartDate);
        estDate = new Date(values.establishedDate);
        var currentDate = new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        );

        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format(newDate))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(minRecoupmentDateTextTemp);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format(newDate))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === optSelectOne &&
        values.paymentScheduleType === 'A'
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === '' &&
        values.paymentScheduleType === 'A'
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.';
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
          );
        }
      }
      if (
        values.interestCalculation === 'Y' &&
        values.interestPercentage === '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage &&
        values.interestPercentage !== '' &&
        values.interestPercentage
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
        values.paymentScheduleType === 'A'
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError('');
        setRecoupmentStartDateError('');
        setFrequencyError('');
        setNumberofinstallmentError('');
        setInstallmentCalError('');
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }
    const index = attachmentsTableData.length - 1;
    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      if (paymentScheduleChanged || paymentSettingChanged) {
        value.auditUserID = logInUserID;
        value.auditTimeStamp = getUTCTimeStamp();
        value.addedAuditUserID = logInUserID;
        value.addedAuditTimeStamp = getUTCTimeStamp();
      } else {
        if (value.edited && value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
        }
        if (!value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
          value.addedAuditUserID = logInUserID;
          value.addedAuditTimeStamp = getUTCTimeStamp();
        }
      }
      value.installmentAmount = numeral(value.installmentAmount).format('0.00');
      value.recoupedAmount = numeral(value.recoupedAmount).format('0.00');
    });
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString('en-US', {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    if (!newData) {
      grossLevelDetails.auditUserID = logInUserID;
      grossLevelDetails.auditTimeStamp = getUTCTimeStamp();
      grossLevelDetails.receivableFCN = values.fcn;
      grossLevelDetails.balanceAmount = values.balanceAmount;
      grossLevelDetails.approvalIndicator = values.approval === 'Y' ? '1' : '0';
      grossLevelDetails.atrNumber = values?.atrNumber?.toUpperCase();
      grossLevelDetails.relatedFTTCN = values.relatedFTTCN;
      grossLevelDetails.arAgeInDays = values.arAgeInDays;
      grossLevelDetails.grossLevelReceivableVO.beginDateStr =
        selectedBeginDate &&
          (reasonCd.includes(values.reasonCode?.split("-")[0]))
          ? moment(selectedBeginDate).format(newDate)
          : null;
      grossLevelDetails.grossLevelReceivableVO.endDateStr =
        selectedEndDate &&
          (reasonCd.includes(values.reasonCode?.split("-")[0]))
          ? moment(selectedEndDate).format(newDate)
          : null;
      grossLevelDetails.accountVO.cos = values.categoryOfService;
      grossLevelDetails.adjustedArAmt = values.adjustedARAmount;
      grossLevelDetails.accountVO.scoaFiscalYear = values.fiscalYear;
      grossLevelDetails.recoupmentVO.recoupmentTypeCode =
        values.recoupmentTypeCode !== optSelectOne
          ? values.recoupmentTypeCode
          : null;
      grossLevelDetails.recoupmentVO.installmentAmount = values.installmentValue
        ? numeral(values.installmentValue).format('0,0.00')
        : null;
      grossLevelDetails.recoupmentVO.percentageOriginalAmount = values.percentValue
        ? numeral(values.percentValue).format('0.00')
        : null;
      if (values.recoupmentTypeCode === 'D') {
        grossLevelDetails.recoupmentVO.startDateStr = values.establishedDate;
      } else {
        grossLevelDetails.recoupmentVO.startDateStr =
          values.recoupmentTypeCode === 'N' || values.recoupmentTypeCode === 'S'
            ? null
            : selectedRecoupmentDate
              ? moment(selectedRecoupmentDate).format(newDate)
              : null;
      }
      grossLevelDetails.recoupmentVO.frequency =
        values.frequency !== optSelectOne ? values.frequency : null;
      grossLevelDetails.schedulePaymentList = paymentScheduleData;
      grossLevelDetails.exemptionsVO.interestExemptionsInd =
        values.exemptFromInterest === 'Y' ? '1' : '0';
      grossLevelDetails.exemptionsVO.collectionAgencyInd =
        values.exemptFromCollectionAgency === 'Y' ? '1' : '0';
      grossLevelDetails.exemptionsVO.penaltyExemptionsInd =
        values.exemptFromPenalty === 'Y' ? '1' : '0';
      grossLevelDetails.accountVO.fundCode = values.collocationCode;
      grossLevelDetails.originalAmount = values.originalARAmount;
      grossLevelDetails.grossLevelReceivableVO.appealIndicator =
        values.underAppeal === 'N' ? 0 : 1;
      grossLevelDetails.deleteSchedulePaymentList =
        deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
      grossLevelDetails.schedulePaymentMap = schedulePaymentMap;
      grossLevelDetails.schedulePaymentMapFromDB = schedulePaymentMap;
      grossLevelDetails.noteSetVO = notesInput;
      grossLevelDetails.paymentScheduleType =
        values.paymentScheduleType === optSelectOne
          ? null
          : values.paymentScheduleType;
      grossLevelDetails.recoupmentStartDate = selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format(newDate)
        : null;
      grossLevelDetails.numberofInstallments = values.numberofInstallments
        ? values.numberofInstallments
        : null;
      grossLevelDetails.interestCalculation = values.interestCalculation;
      grossLevelDetails.interestPercentage =
        values.interestPercentage !== ''
          ? numeral(values.interestPercentage).format('0.00')
          : null;
      grossLevelDetails.paymentScheduleFrequency =
        values.paymentScheduleFrequency !== optSelectOne
          ? values.paymentScheduleFrequency
          : null;
      grossLevelDetails.payeeStatus =
        values.payeeStatus !== optSelectOne
          ? values.payeeStatus
          : null;
      grossLevelDetails.attachmentVO = null;
      grossLevelDetails.attachmentsList = attachmentVOList;
      grossLevelDetails.paymentInd = paymentInd;
      grossLevelDetails.recoupTypeInd =
        !!(grossLevelDetails.recoupmentTypeCode === 'S' &&
          values.recoupmentTypeCode === 'N');
      if (errorMessagesArray.length === 0) {
        if (!allowNavigation) {
          NoSaveMessage();
        } else {

          grossLevelDetails.accountReceivableReasonCode = grossLevelDetails.accountReceivableReasonCode.split(
            '-'
          )[0];
          onGrossLevelEdit(grossLevelDetails, index);

        }
      }
    } else {
      newData.auditUserID = logInUserID;
      newData.auditTimeStamp = getUTCTimeStamp();
      newData.receivableFCN = values.fcn;
      newData.atrNumber = values?.atrNumber?.toUpperCase();
      newData.balanceAmount = values.balanceAmount;
      newData.approvalIndicator = values.approval === 'Y' ? '1' : '0';
      newData.relatedFTTCN = values.relatedFTTCN;
      newData.arAgeInDays = values.arAgeInDays;
      newData.paymentInd = paymentInd;
      newData.grossLevelReceivableVO.beginDateStr =
        selectedBeginDate &&
		(reasonCd?.includes(values.reasonCode))
          ? moment(selectedBeginDate).format(newDate)
          : null;
      newData.grossLevelReceivableVO.endDateStr =
        selectedEndDate &&
		(reasonCd?.includes(values.reasonCode))
          ? moment(selectedEndDate).format(newDate)
          : null;
      newData.accountVO.cos = values.categoryOfService;
      newData.adjustedArAmt = values.adjustedARAmount;
      newData.accountVO.scoaFiscalYear = values.fiscalYear;
      newData.recoupmentVO.recoupmentTypeCode =
        values.recoupmentTypeCode !== optSelectOne
          ? values.recoupmentTypeCode
          : null;
      newData.recoupmentVO.installmentAmount = values.installmentValue;
      newData.recoupmentVO.percentageOriginalAmount = values.percentValue;
      if (values.recoupmentTypeCode === 'D') {
        newData.recoupmentVO.startDateStr = values.establishedDate;
      } else {
        newData.recoupmentVO.startDateStr = selectedRecoupmentDate
          ? moment(selectedRecoupmentDate).format(newDate)
          : null;
      }
      newData.recoupmentVO.frequency =
        values.frequency !== optSelectOne ? values.frequency : null;
      newData.schedulePaymentList = paymentScheduleData;
      newData.exemptionsVO.interestExemptionsInd =
        values.exemptFromInterest === 'Y' ? '1' : '0';
      newData.exemptionsVO.collectionAgencyInd =
        values.exemptFromCollectionAgency === 'Y' ? '1' : '0';
      newData.exemptionsVO.penaltyExemptionsInd =
        values.exemptFromPenalty === 'Y' ? '1' : '0';
      newData.accountVO.fundCode = values.collocationCode;
      newData.originalAmount = values.originalARAmount;
      newData.noteSetVO = notesInput;
      newData.paymentScheduleType =
        values.paymentScheduleType === optSelectOne
          ? null
          : values.paymentScheduleType;
      newData.recoupmentStartDate = selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format(newDate)
        : null;
      newData.numberofInstallments = values.numberofInstallments
        ? values.numberofInstallments
        : null;
      newData.interestCalculation = values.interestCalculation;
      newData.interestPercentage =
        values.interestPercentage !== ''
          ? numeral(values.interestPercentage).format('0.00')
          : null;
      newData.paymentScheduleFrequency =
        values.paymentScheduleFrequency !== optSelectOne
          ? values.paymentScheduleFrequency
          : null;
      newData.attachmentVO = null;
      newData.attachmentsList = attachmentVOList;
      newData.recoupTypeInd =
        !!(grossLevelDetails.recoupmentTypeCode === 'S' &&
          values.recoupmentTypeCode === 'N');
      newData.payeeStatus = values.payeeStatus !== optSelectOne
        ? values.payeeStatus
        : null;
      if (errorMessagesArray.length === 0) {
        if (!allowNavigation) {
          NoSaveMessage();
        } else {
          newData.accountReceivableReasonCode = newData.accountReceivableReasonCode.split(
            '-'
          )[0];
          onGrossLevelEdit(newData, index);

        }
      }
    }
  };

  const editRow = data => event => {
    seterrorMessages([]);
    setAuditLogRow(data);
    setAdd(false);
    setEditData(data);
    setRetainEdit(data);
    setOpen(true);
    setSelectedDueDate(data.dueDate);
    setPaymentScheduleVO({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainPaymentSchedule({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  const handleDefaultRecoupment = () => {
    setRecoupmentTypeClicked(false);
    if (values.balanceAmount !== '0.00') {
      setAllowNavigation(true);
      onGetRecoupmetDetails(claimsData);
      setRetainValues(values);
    }
  };

  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };
  // Delete Dialog implemented
  const confirmDelete = event => {
    PaymentRowDeleteClick();
    setOpenDialog(false);
  };
  const openDeletePopUpDialog = () => {
    if (rowPaymentScheduleData.rowPaymentScheduleData.length !== 0) {
      setOpenDialog(true);
    }
  };
  const closeDeletePopUpDialog = () => {
    setOpenDialog(false);
  };
  const NavigateToWriteOff = () => {
    checkValidationLinks();
    if (values.recoupmentTypeCode === 'S') {
      if (paymentScheduleData.length === 0) {
        errorMessagesArray.push(ErrorConstants.PAYMENT_SCHEDULE_ERROR);
      }
    }
    if (errorMessagesArray.length === 0) {
      if (
        grossLevelDetails.balanceAmount === '0' ||
        grossLevelDetails.balanceAmount === '0.0' ||
        grossLevelDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        grossLevelDetails.auditUserID = logInUserID;
        grossLevelDetails.auditTimeStamp = getUTCTimeStamp();
        grossLevelDetails.receivableFCN = values.fcn;
        grossLevelDetails.balanceAmount = values.balanceAmount;
        grossLevelDetails.approvalIndicator =
          values.approval === 'Y' ? '1' : '0';
        grossLevelDetails.relatedFTTCN = values.relatedFTTCN;
        grossLevelDetails.arAgeInDays = values.arAgeInDays;
        grossLevelDetails.grossLevelReceivableVO.beginDateStr =
          selectedBeginDate &&
		  (reasonCd?.includes(values.reasonCode))
            ? moment(selectedBeginDate).format(newDate)
            : null;
        grossLevelDetails.grossLevelReceivableVO.endDateStr =
          selectedEndDate &&
		  (reasonCd?.includes(values.reasonCode))
            ? moment(selectedEndDate).format(newDate)
            : null;
        grossLevelDetails.accountVO.cos = values.categoryOfService;
        grossLevelDetails.adjustedArAmt = values.adjustedARAmount;
        grossLevelDetails.atrNumber = values.atrNumber;
        grossLevelDetails.accountVO.scoaFiscalYear = values.fiscalYear;
        grossLevelDetails.recoupmentVO.recoupmentTypeCode =
          values.recoupmentTypeCode !== optSelectOne
            ? values.recoupmentTypeCode
            : null;
        grossLevelDetails.recoupmentVO.installmentAmount = values.installmentValue
          ? numeral(values.installmentValue).format('0,0.00')
          : null;
        grossLevelDetails.recoupmentVO.percentageOriginalAmount = values.percentValue
          ? numeral(values.percentValue).format('0.00')
          : null;
        grossLevelDetails.recoupmentVO.startDateStr =
          values.recoupmentTypeCode === 'N' || values.recoupmentTypeCode === 'S'
            ? null
            : selectedRecoupmentDate
              ? moment(selectedRecoupmentDate).format(newDate)
              : null;
        grossLevelDetails.recoupmentVO.frequency =
          values.frequency !== optSelectOne ? values.frequency : null;
        grossLevelDetails.schedulePaymentList = paymentScheduleData;
        grossLevelDetails.exemptionsVO.interestExemptionsInd =
          values.exemptFromInterest === 'Y' ? '1' : '0';
        grossLevelDetails.exemptionsVO.collectionAgencyInd =
          values.exemptFromCollectionAgency === 'Y' ? '1' : '0';
        grossLevelDetails.exemptionsVO.penaltyExemptionsInd =
          values.exemptFromPenalty === 'Y' ? '1' : '0';
        grossLevelDetails.accountVO.fundCode = values.collocationCode;
        grossLevelDetails.originalAmount = values.originalARAmount;
        grossLevelDetails.grossLevelReceivableVO.appealIndicator =
          values.underAppeal === 'N' ? 0 : 1;
        grossLevelDetails.deleteSchedulePaymentList =
          deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
        grossLevelDetails.schedulePaymentMap = schedulePaymentMap;
        grossLevelDetails.schedulePaymentMapFromDB = schedulePaymentMap;
        grossLevelDetails.noteSetVO = notesInput;
        seterrorMessages([]);
        setSystemSuccesMessages([]);

        dispatch(getWriteOffDetailsReset());
        fetchWriteOffData(grossLevelDetails);
      }
    }
  };

  const NavigateToWriteOffRestablish = () => {
    checkValidationLinks();
    if (values.recoupmentTypeCode === 'S') {
      if (paymentScheduleData.length === 0) {
        errorMessagesArray.push(ErrorConstants.PAYMENT_SCHEDULE_ERROR);
      }
    }
    if (errorMessagesArray.length === 0) {
      if (
        grossLevelDetails.balanceAmount === '0' ||
        grossLevelDetails.balanceAmount === '0.0' ||
        grossLevelDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(ErrorConstants.BALNACE_AMOUNT_ZERO_ERROR);
        seterrorMessages(systemErrorMessges);
      } else {
        grossLevelDetails.auditUserID = logInUserID;
        grossLevelDetails.auditTimeStamp = getUTCTimeStamp();
        grossLevelDetails.receivableFCN = values.fcn;
        grossLevelDetails.balanceAmount = values.balanceAmount;
        grossLevelDetails.approvalIndicator =
          values.approval === 'Y' ? '1' : '0';
        grossLevelDetails.relatedFTTCN = values.relatedFTTCN;
        grossLevelDetails.arAgeInDays = values.arAgeInDays;
        grossLevelDetails.grossLevelReceivableVO.beginDateStr =
          selectedBeginDate &&
		  (reasonCd?.includes(values.reasonCode))
            ? moment(selectedBeginDate).format(newDate)
            : null;
        grossLevelDetails.grossLevelReceivableVO.endDateStr =
          selectedEndDate &&
		  (reasonCd?.includes(values.reasonCode))
            ? moment(selectedEndDate).format(newDate)
            : null;
        grossLevelDetails.accountVO.cos = values.categoryOfService;
        grossLevelDetails.adjustedArAmt = values.adjustedARAmount;
        grossLevelDetails.atrNumber = values?.atrNumber;
        grossLevelDetails.accountVO.scoaFiscalYear = values.fiscalYear;
        grossLevelDetails.recoupmentVO.recoupmentTypeCode =
          values.recoupmentTypeCode !== optSelectOne
            ? values.recoupmentTypeCode
            : null;
        grossLevelDetails.recoupmentVO.installmentAmount = values.installmentValue
          ? numeral(values.installmentValue).format('0,0.00')
          : null;
        grossLevelDetails.recoupmentVO.percentageOriginalAmount = values.percentValue
          ? numeral(values.percentValue).format('0.00')
          : null;
        grossLevelDetails.recoupmentVO.startDateStr =
          values.recoupmentTypeCode === 'N' || values.recoupmentTypeCode === 'S'
            ? null
            : selectedRecoupmentDate
              ? moment(selectedRecoupmentDate).format(newDate)
              : null;
        grossLevelDetails.recoupmentVO.frequency =
          values.frequency !== optSelectOne ? values.frequency : null;
        grossLevelDetails.schedulePaymentList = paymentScheduleData;
        grossLevelDetails.exemptionsVO.interestExemptionsInd =
          values.exemptFromInterest === 'Y' ? '1' : '0';
        grossLevelDetails.exemptionsVO.collectionAgencyInd =
          values.exemptFromCollectionAgency === 'Y' ? '1' : '0';
        grossLevelDetails.exemptionsVO.penaltyExemptionsInd =
          values.exemptFromPenalty === 'Y' ? '1' : '0';
        grossLevelDetails.accountVO.fundCode = values.collocationCode;
        grossLevelDetails.originalAmount = values.originalARAmount;
        grossLevelDetails.grossLevelReceivableVO.appealIndicator =
          values.underAppeal === 'N' ? 0 : 1;
        grossLevelDetails.deleteSchedulePaymentList =
          deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
        grossLevelDetails.schedulePaymentMap = schedulePaymentMap;
        grossLevelDetails.schedulePaymentMapFromDB = schedulePaymentMap;
        grossLevelDetails.noteSetVO = notesInput;
        seterrorMessages([]);
        setSystemSuccesMessages([]);

        dispatch(getWriteOffRestablishmentDetailsReset());
        fetchWriteOffReEstablishmentData(grossLevelDetails);
      }
    }
  };

  const amountFormatCheck = value => {
    return typeof value === 'string'
      ? parseFloat(value.replace(/[^\d\.\-]/g, ''))
        .toString()
        .indexOf('.') === -1
        ? `${parseFloat(value.replace(/[^\d\.\-]/g, ''))}.00`
        : parseFloat(value.replace(/[^\d\.\-]/g, ''))
      : numeral(value).format('0.00');
  };
  const sharedPropsDialog =
    !add && showLogDialogTable ? { maxWidth: 'xl', fullWidth: true } : null;

  const valuesFromAREdit = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.values;
  const payloadPayees = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.payloadPayees;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setDialogOpen(false);
    setFocusCheck(false);
    props.history.push({
      pathname: '/FinancialAccountInquiry',
      tableRender: !props.history.location?.isFromAdd,
      state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
    });
  };

  const majorCancel = () => {
    setAllowNavigation(false);
    seterrorMessages([]);
    setTimeout(() => {
      if (focusCheck) {
        setDialogOpen(true);
      } else {
        setDialogOpen(false);
        props.history.push({
          pathname: '/FinancialAccountInquiry',
          tableRender: !props.history.location?.isFromAdd,
          state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }, 100);
  };

    const getRecord = (dropdownList,elName,inputVal) => {
      let updatedInputVal = inputVal?.split('-')[0];
      let record;
      if (dropdownList &&  Object.keys(dropdownList).length > 0 ){
         record =  dropdownList[elName]?.find(value => value.code === updatedInputVal);
      }
      return record;
    };

  // getting payeerecord to set desc
  let payeeRecord = getRecord(addDropdowns,'G1#G_CMN_ENTY_TY_CD',values.payeeType);
  let payeeVal = payeeRecord !== undefined ? payeeRecord.description :  null;

  //getting ActivityTypeRecord to set desc
  let activityCodeRecord = getRecord(addDropdowns,'F1#F_ACTV_TY_CD',values.activityCode);
  let activityCodeVal = activityCodeRecord !== undefined ? activityCodeRecord.description :  null;

  //getting reasonCodeRecord  to set desc
  let reasonCodeRecord = getRecord(addDropdowns,'F1#F_RSN_CD',values.reasonCode);
  let reasonCodeVal = reasonCodeRecord !== undefined ? reasonCodeRecord.description :  null;

  //getting payeeIDType dropdown based on PayeeType
  let payeeIDTypeDropdown = values.payeeType === 'P' ? 'F1#P_ALT_ID_TY_CD' :
  values.payeeType === 'M' ? 'F1#B_ALT_ID_TY_CD' :
  values.payeeType === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD' :
  values.payeeType === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  //getting providerIDTypeRecord to set desc
  let payeeIDTypeRecord = getRecord(addDropdowns,payeeIDTypeDropdown,values.payeeIdTypeCode);
  let payeeIDCodeVal = payeeIDTypeRecord !== undefined ? payeeIDTypeRecord.description :  null;

  return (
    <div>
      <div className="tabs-container" ref={toPrintRef}>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <div
          align="right"
          className="nav-menu"
          style={{ paddingRight: '5px' }}
        >
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARAdjust}>&nbsp;&nbsp;Adjust</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Adjust</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARReverse} data-testid="reverse_hyperlink_testid">&nbsp;&nbsp;Reverse</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Reverse</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARTransfer}>&nbsp;&nbsp;Transfer</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Transfer</span>
          )}

          {writeOff ? (
            userInquiryPrivileges ? (
              <span>
                <a onClick={NavigateToWriteOff}>&nbsp;&nbsp;Write-Off</a>
              </span>
            ) : (
              <span>&nbsp;&nbsp;Write-Off</span>
            )
          ) : userInquiryPrivileges ? (
            <span>
              <a onClick={NavigateToWriteOffRestablish}>
                &nbsp;&nbsp;Write-Off Re-establish
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Write-Off Re-establish</span>
          )}
        </div>

        <ErrorMessages errorMessages={[...new Set(errorMessages)]} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left" data-testid='test-PageTitle'>Edit Gross Level</h1>
          <div className="float-right mt-2">
            <Button
			data-testid='test-Save'
              className="btn btn-success ml-1"
              onClick={() => saveGrossLevelEdit()}
              disabled={!userInquiryPrivileges}
            >
              {' '}
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/FinancialAccountGrossLevelUpdate' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div class="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                  id="standard-GrossUpdatePayeeType"
                label="Payee Type"
                value={values.payeeType}
                onChange={handleChange('payeeType')}
                inputProps={{ maxlength: 3 }}
                  disabled={true}
                dropdownList={addDropdowns && addDropdowns?.['G1#G_CMN_ENTY_TY_CD']?.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                  id="standard-GrossUpdatePayeeIDType"
                  label="Payee ID Type Code"
                value={values.payeeIdTypeCode}
                onChange={handleChange('payeeIdTypeCode')}
                inputProps={{ maxlength: 3 }}
                  disabled={true}
                dropdownList={addDropdowns && addDropdowns?.[payeeIDTypeDropdown]?.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-GrossUpdatePayeeID"
                  fullWidth
                  label="Payee ID"
                  InputProps={{ readOnly: true, className: muiDisabled }}
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md">
                <TextField
                  InputProps={{ readOnly: true, className: muiDisabled }}
                  fullWidth
                  id="standard-GrossUpdatePayeeName"
                  label="Payee Name "
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form  input-md has-link">
                <TextField
                  InputProps={{ readOnly: true, className: muiDisabled,
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          values.systemPayeeId !== ''
                            ? <Link to={redirectSystemID(values.systemPayeeId)} target="_blank">
                              { values.systemPayeeId }
                            </Link> : null
                        }

                      </InputAdornment>
                    ) }}
                  fullWidth
                  id="standard-GrossUpdateSystemPayeeID"
                  label="System Payee ID"
                  placeholder=""
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                ></TextField>
              </div>
              <NativeDropDown
                  disabled={true}
                  id="standard-GrossUpdateActivityType"
                label="Activity Type"
                value={values.activityCode}
                onChange={handleChange('activityCode')}
                  inputProps={{ maxlength: 3 }}
                dropdownList={addDropdowns && addDropdowns?.['F1#F_ACTV_TY_CD']?.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="reasonCode"
                  fullWidth
                  label="Reason Code"
                  value={reasonCodeData || values.reasonCode }
                  InputProps={{ readOnly: true, className: muiDisabled }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  title={
                    rcTitle ||
                    getLongDescription(reasonCodeDD, values.reasonCode)
                  }
                ></TextField>
              </div>
            </div>
          </form>
          <div className="tab-panelbody m-3">
            <div className="tab-holder mb-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Gross Level Detail" />
                  <Tab label="Interest and Penalty" />
                  <Tab label="Payment Schedule" data-testid = "payment_schedule_tab_testid" />
                  <Tab label="History" style={{ marginLeft: '-7px' }} />
                  <Tab label="Notes" style={{ marginLeft: '-6px' }} />
                  <Tab label="Attachment" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <GrossLevelDetailsComponent
                    addDropdowns={addDropdowns}
                      values={values}
                      ARletterSent={ARletterSent}
                      handleChange={handleChange}
                      handleDecimalValues={handleDecimalValues}
                      errors={[
                        showOriginalArAmmountError,
                        showFiscalYearError,
                        showCollocationCodeError,
                        showRecoupmentTypeCodeError,
                        showInvalidBeginDateError,
                        showInvalidEndDateError,
                        showEndDateLesserError,
                        showBeginDateRequiredError,
                        showEndDateRequiredError,
                        showPercentError,
                        showInstallementError,
                        showStartDateError,
                        showStartDateRequiredError,
                        showMinBeginDateError,
                        showMinEndDateError,
                        showMinRecoupmentDateError,
                        showFrequencyError,
                        showATRError
                      ]}
                      errorText={[
                        showOriginalArAmountErrorText,
                        showFiscalYearErrorText,
                        showCollocationCodeErrorText,
                        showRecoupmentTypeCodeErrorText,
                        showInvalidBeginDateText,
                        showInvalidEndDateInvalidText,
                        showEndDateLesserErrorText,
                        showBeginDateRequiredErrorText,
                        showEndDateRequiredErrorText,
                        showPercentErrorText,
                        showInstallmentErrorText,
                        showStartDateErrorText,
                        showStartDateRequiredErrorText,
                        showMinBeginDateErrorText,
                        showMinEndDateErrorText,
                        showMinRecoupmentDateErrorText,
                        showFrequencyErrorText,
                        showATRErrorText
                      ]}
                      selectedBeginDate={selectedBeginDate}
                      handleBeginDateChange={handleBeginDateChange}
                      selectedEndDate={selectedEndDate}
                      handleEndDateChange={handleEndDateChange}
                      selectedRecoupmentDate={selectedRecoupmentDate}
                      handleRecoupmentDateChange={handleRecoupmentDateChange}
                      defaultRecoupment={handleDefaultRecoupment}
                      amountObj={amountObj}
                      formatDecimalField={formatDecimalField}
                      formatPercent={formatPercent}
                      removeFormatDecimalFieldInstallment={
                        removeFormatDecimalFieldInstallment
                      }
                      frequencyDataDropdown={frequencyDataDropdown}
                    ></GrossLevelDetailsComponent>
                  </div>
                )}

              </TabPanel>

              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <GrossLevelExemptionDetailComponent
                      values={values}
                      calculatedInterest={grossLevelDetails.calculatedInterest}
                      calculatedFee={grossLevelDetails.calculatedFees}
                      calculatedPenalty={grossLevelDetails.calculatedPenalty}
                      handleChange={handleChange}
                    ></GrossLevelExemptionDetailComponent>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <>
                    <div className="tab-holder">
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NativeDropDown
                              className='min-md'
                                id="standard-GrossDetailsCategoryofService"
                                label="Payment Schedule"
                                value={values.paymentScheduleType}
                              onChange={handleChange('paymentScheduleType')}
                              inputProps={{ maxlength: 3, "data-testid": "payment_schedule_testid" }}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    manualEditFlag
                                }
                              isRequired={values.recoupmentTypeCode === 'S'}
                              errTxt={
                                  paymentScheduleTypeError !== ''
                                    ? paymentScheduleTypeError
                                    : null
                                }
                              dropdownList={paymentScheduleTypeData.map(
                                  (option, index) => (
                                  <option key={index} value={option.code}>
                                      {option.description}
                                  </option>
                                  )
                                )}
                            />
                            <div className="mui-custom-form with-date">
                              <KeyboardDatePicker
                                fullWidth
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="GrossLevelDetailBegindate-picker-dialog"
                                label="Recoupment Start Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                inputProps = {{ "data-testid": "recoupment_start_date_testid"}}
                                minDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                initialFocusedDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                value={selectedRecoupmentStartDate}
                                onChange={handleRecoupmentStartDateChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    manualEditFlag
                                }
                                required={values.recoupmentTypeCode === 'S'}
                                helperText={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                                error={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="mui-custom-form input-md override-m-2">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Number of Installments"
                              placeholder=""
                              inputProps={{ maxlength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={values.numberofInstallments}
                              onChange={handleChange('numberofInstallments')}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                              }
                              required={values.paymentScheduleType === 'A'}
                              helperText={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              error={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                  ''
                                );
                              }}
                            ></TextField>
                          </div>
                          <NativeDropDown
                            className='min-md override-m-2'
                              id="standard-GrossDetailsCategoryofService"
                              label="Frequency"
                              value={values.paymentScheduleFrequency}
                              onChange={handleChange(
                                'paymentScheduleFrequency'
                              )}
                              inputProps={{ maxlength: 3 }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                              }
                            isRequired={values.paymentScheduleType === 'A'}
                            errTxt={
                                frequencyError !== '' ? frequencyError : null
                              }
                            dropdownList={frequencyRecoupData && frequencyRecoupData?.map((option, index) => (
                              <option key={index} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                        </div>
                        <div className="form-wrapper">
                          <div
                            className="mui-custom-form min-md"
                            style={{ lineHeight: '100%' }}
                          >
                            <label className="MuiFormLabel-root small-label">
                              Interest Calculation
                            </label>
                            <div className="sub-radio no-margin">
                              <Radio
                                value="Y"
                                id="GroosLevelDetailsapprovalIdYes"
                                checked={values.interestCalculation === 'Y'}
                                onChange={handleChange('interestCalculation')}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                for="GroosLevelDetailsapprovalIdYes"
                              >
                                Yes
                              </label>
                              <Radio
                                value="N"
                                id="GroosLevelDetailsapprovalIdNo"
                                checked={values.interestCalculation === 'N'}
                                onChange={handleChange('interestCalculation')}
                                className="ml-2"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                for="GroosLevelDetailsapprovalIdNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Interest Percentage"
                              placeholder=""
                              inputProps={{ maxlength: 14 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              value={values.interestPercentage}
                              onBlur={formatDecimalField('interestPercentage')}
                              onFocus={removeFormatDecimalFieldPercent(
                                'interestPercentage'
                              )}
                              onChange={handleChange('interestPercentage')}
                              onKeyDown={evt =>
                                evt.key === 'e' && evt.preventDefault()
                              }
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  values.interestCalculation === 'N' ||
                                  manualEditFlag
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ''
                                );
                              }}
                              required={values.interestCalculation === 'Y'}
                              helperText={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                              error={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                            ></TextField>
                          </div>
                        </div>
                      </form>
                      <div className="float-right mt-3">
                        <Button
                          className="btn-text-main-add btn-success"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType !== 'A' ||
                              manualEditFlag
                          }
                          title={'Calculate'}
                          onClick={calculateSchedule}
                        >
                          Calculate
                        </Button>
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={openDeletePopUpDialog}
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType === 'A' ||
                              grossLevel.balanceAmount <= 0
                          }
                          title={'Delete'}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpenPaymentSchedule}
                          data-testid = "payment_schedule_add_btn"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType === 'A' ||
                              grossLevel.balanceAmount <= 0
                          }
                          title={'Add Payment Schedule'}
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                      <div className="clearfix"></div>

                      <div className="my-3 p-0">
                        {values.paymentScheduleType === 'M' ? (
                          <PaymentScheduleTable
                            tableData={paymentScheduleData}
                            onRowClick={editRow}
                            onTableCellButtonClick={onClickAuditDialogLog}
                            uniqueKey={'scheduleSK'}
                            setClose={setClose}
                            close={close}
                            rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                            isSearch={false}
                          />
                        ) : (
                          <PaymentScheduleTable
                            tableData={paymentScheduleData}
                            onRowClick={editRow}
                            rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                            isSearch={true}
                            onTableCellButtonClick={onClickAuditDialogLog}
                            uniqueKey={'scheduleSK'}
                            setClose={setClose}
                            close={close}
                          />
                        )}
                      </div>
                      {open ? (
                        <div className="tabs-container" id="form_pop_up">
                          <div className="tab-header  mini-tab-header">
                            <div className="tab-heading float-left">
                              {add
                                ? 'Add Payment Schedule'
                                : 'Edit Payment Schedule'}
                            </div>
                            <div className="float-right mt-1">
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={savePaymentSchedule}
                                disabled={values.paymentScheduleType === 'A' || !userInquiryPrivileges}
                                title={add ? 'Add' : 'Update'}
                                data-testid = "payment_schedule_save_btn"
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>
                                {add ? 'Add' : 'Update'}
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="bt-reset btn-transparent ml-1"
                                onClick={resetPaymentSchedule}
                                disabled={values.paymentScheduleType === 'A' || !userInquiryPrivileges}
                                title={'Reset'}
                              >
                                <i class="fa fa-undo" aria-hidden="true"></i>
                                Reset
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-primary btn btn-outlined ml-1"
                                onClick={handleClose}
                                title={'Cancel'}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <div className="form-border my-3">
                            <form autoComplete="off">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="form-wrapper">
                                  <div className="mui-custom-form with-date override-width-20">
                                    <KeyboardDatePicker
                                      maxDate={Date.parse(
                                        '31 Dec 9999 00:00:00 GMT'
                                      )}
                                      required
                                      fullWidth
                                      id="dueDate"
                                      label="Due Date"
                                      inputProps = {{ "data-testid": "payment_schedule_due_date" }}
                                      minDate={
                                        new Date(
                                          new Date().toLocaleString('en-US', {
                                            timeZone:
                                              process.env.REACT_APP_TIMEZONE
                                          })
                                        )
                                      }
                                      initialFocusedDate={
                                        new Date(
                                          new Date().toLocaleString('en-US', {
                                            timeZone:
                                              process.env.REACT_APP_TIMEZONE
                                          })
                                        )
                                      }
                                      format="MM/dd/yyyy"
                                      placeholder="mm/dd/yyyy"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      value={selectedDueDate}
                                      onChange={handleDueDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                      }}
                                      disabled={
                                        values.paymentScheduleType === 'A'
                                      }
                                      helperText={
                                        showDueDateError
                                          ? showDueDateErrorText
                                          : null || showDueDateGreaterError
                                            ? showDueDateGreaterErrorText
                                            : null || showMinDueDateError
                                              ? showMinDueDateErrorText
                                              : null || showDueDateInvalidError
                                                ? showDueDateInvalidErrorText
                                                : null
                                      }
                                      error={
                                        showDueDateError
                                          ? showDueDateErrorText
                                          : null || showDueDateGreaterError
                                            ? showDueDateGreaterErrorText
                                            : null || showMinDueDateError
                                              ? showMinDueDateErrorText
                                              : null || showDueDateInvalidError
                                                ? showDueDateInvalidErrorText
                                                : null
                                      }
                                    />
                                  </div>
                                  <div className="mui-custom-form input-md override-width-20">
                                    <TextField
                                      id="amountdue"
                                      fullWidth
                                      label="Installment Amount"
                                      required
                                      inputProps={{ maxlength: 14, "data-testid": "payment_schedule_due_amount" }}
                                      value={
                                        paymentScheduleVO.installmentAmount
                                      }
                                      onChange={handleChangePaymentSchedule(
                                        'installmentAmount'
                                      )}
                                      onBlur={formatDecimalFieldInstallment(
                                        'installmentAmount'
                                      )}
                                      onFocus={removeFormatDecimalFieldAmountDue(
                                        'installmentAmount'
                                      )}
                                      disabled={
                                        values.paymentScheduleType === 'A'
                                      }
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                      helperText={
                                        showAmountDueError
                                          ? showAmountDueErrorText
                                          : null || showNegativeAmountError
                                            ? showNegativeAmmountErrorText
                                            : null ||
                                              showGreaterThanZeroAmountError
                                              ? showGreaterThanZeroAmountText
                                              : null || showOriginalArAmountPopError
                                                ? showOriginalArAmountPopErrorText
                                                : null
                                      }
                                      error={
                                        showAmountDueError
                                          ? showAmountDueErrorText
                                          : null || showNegativeAmountError
                                            ? showNegativeAmmountErrorText
                                            : null ||
                                              showGreaterThanZeroAmountError
                                              ? showGreaterThanZeroAmountText
                                              : null || showOriginalArAmountPopError
                                                ? showOriginalArAmountPopErrorText
                                                : null
                                      }
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                          ''
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                  <div className="mui-custom-form input-md override-width-20">
                                    <TextField
                                      id="amountdue"
                                      fullWidth
                                      label="Recouped Amount"
                                      required
                                      disabled
                                      inputProps={{ maxlength: 14 }}
                                      value={paymentScheduleVO.recoupedAmount}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                    ></TextField>
                                  </div>
                                </div>
                              </MuiPickersUtilsProvider>
                            </form>
                          </div>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditDialogRowData}
                                setAuditRowData={setAuditDialogRowData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{' '}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {value === 3 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <GrossLevelHistoryTableComponent
                        values={values}
                        historyPayload={historyPayload}
                      />
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                {value === 4 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                      setFocusCheck={setFocusCheck}
                      userInquiryPrivileges={userInquiryPrivileges}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={5}
              >
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={seterrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                  />
                </div>
              </TabPanel>
              {showLogTable ? (
                <div id="audit-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />{' '}
                </div>
              ) : null}
            </div>
          </div>
          <Dialog
            className="custom-dialog dialog-500 with-padding"
            fullWidth={true}
            maxWidth={'sm'}
            open={openDialog}
            disableBackdropClick
          >
            <DialogContent dividers>
              <div>Are you sure you want to Delete?</div>
            </DialogContent>

            <DialogActions>
              <Button
                className="btn btn-primary ml-1"
                onClick={confirmDelete}
                disabled={!userInquiryPrivileges}
              >
                OK
              </Button>
              <Button
                className="btn btn-primary ml-1"
                onClick={closeDeletePopUpDialog}
                disabled={!userInquiryPrivileges}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                Unsaved changes will be lost. <br />
                Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
              STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
              CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
